.battery {
  position: relative;
  display: inline-block;
  transition: all 0.2s ease-in-out;
  // margin: 2px;
  background: transparent;
  width: 22px;
  height: 11px;
  

  .charge {
    position: absolute;
    height: 11px;
    background: #333;
    width: 0%; //Max: 100%

    .fa {
      color: #fff;
      font-size: 9px;
      left: 9px;
      position: absolute;
      top: 1px;
      display: none;
    }
  }

  &:before {
    content: "";
    display: block;
    background: transparent;
    border: 1px solid #333;
    margin: -2px;
    width: 26px;
    height: 15px;
    position: absolute;
    border-radius: 2px;
  }

  &:after {
    content: "";
    display: block;
    background: transparent;
    border-right: 2px solid #333;
    margin: 2px 20px;
    width: 6px;
    height: 7px;
    position: absolute;
    border-radius: 2px;
  }
}

.mb-navbar {
  &.affix-top {
    .battery {
      margin-top: 39px;
      transition: all 0.2s ease-in-out;
    }
  }

  .battery {
    margin-top: 21px;

    .charge {
      background: $primary-color;
    }
  }
}