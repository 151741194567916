.card {

  width: 100%;

  .card-reveal,
  .card-content {
    padding: 10px 20px;
    
    h1, h2, h3, h4, h5 {
      margin-top: 10px;
    }

    p {
      margin-bottom: 10px;
      margin-top: 0;
    }
  }

  .card-image {
    width: 100%;
    line-height: 1;

    .card-title {
      width: 100%;      
    }
  }

  .card-reveal .card-title {
    color: $primary-color;
    i {
      cursor: pointer;
    }
  }
}

.activator,
.activator i {
  cursor: pointer;
}

.slider .slides {
  background: transparent;
  min-height: 200px;
}


.hoverable-white,
.hoverable-light {
  transition: box-shadow .25s;
  box-shadow: 0;
}

.hoverable-light:hover {
  transition: box-shadow .25s;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 3px 10px 0 rgba(0, 0, 0, 0.1);
}

.hoverable-white:hover {
  transition: all .25s;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 3px 10px 0 rgba(0, 0, 0, 0.1);
  background: #fff;
}

.tabs {
  overflow: hidden;
  background: transparent;
}
