.modal {
  .modal-content {
    background: $primary-color-dark;
  }
}

#requestForm, #requestSuccess {
  padding: 0;
  margin: 0;

  .close {
    position: absolute;
    right: 0px;
    top: 0px;
    color: $primary-color-light;
    text-shadow: none;
    font-size: 42px;
    padding: 10px 15px;

    &:hover, &:focus {
      background-color: transparent;
      color: #fff;
    }
  }

  label, input, textarea {
    color: #fff !important;
  }

  .btn-primary {
    padding-left: 40px;
    padding-right: 40px;
  }

  .input-field {

    input[type=text] {
      
      border-color: $primary-color-light;
      &:focus:not([readonly]) {
        box-shadow: 0 1px 0 0 $primary-color-light;
      }
    }
  }

  textarea {
    border: none;
    // min-height: 40px;
    // background: $primary-color;

    border-bottom: 1px solid $primary-color-light;
    outline: none;

    &:focus {
        box-shadow: 0 1px 0 0 $primary-color-light;
    }
  }

  [type="checkbox"].filled-in + label:after {
    border-color: $primary-color-light;
    left: 10px;
  }

  [type="checkbox"].filled-in + label:before {
    left: 11px;
  }

  &.requestFormInline {
    label, input, textarea {
      color: #707070 !important;
    }

    .input-field {

      input[type=text] {
        
        border-color: $gray-3;
        &:focus:not([readonly]) {
          box-shadow: 0 1px 0 0 $gray-3;
        }
      }
    }

    textarea {
      border: none;
      border-bottom: 1px solid $gray-3;

      &:focus {
        box-shadow: 0 1px 0 0 $gray-3;
      }
    }

    [type="checkbox"].filled-in + label:after {
      border-color: $gray-3;
    }

    #requestError {
      color: #707070 !important;
    }
  }
}