.mb-navbar {
  background-color: rgba(255,255,255,0.95);
  background-color: #fff;
  transition: all 0.2s linear;
  width: 100%;
  z-index: 1000;
  border-radius: 0px;
  // box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
  border-width: 0px;
  margin-bottom: 0;
  padding-top: 0px;

  &.mb-navbar-bottom {
    margin-top: -100px;
    top: 100px;
  }

  &.mb-navbar-middle {

    &.affix {
      top: 0;
    }

    &:not(.affix) {
      position: absolute;
    }
  }

  &.mb-navbar-top {
    position: fixed;
    top: 0px;
  }

  &.affix-top {
    background-color: #fff;
    transition: all 0.2s ease-in-out;

    &.mb-navbar-transparent {
      border-top: none;
      background: none;
      box-shadow: none;
    }

    &.mb-navbar-bottom {
      position: static;
    }

    .navbar-brand { 
      font-size: 34px;
      padding: 26px 12px 22px;
      transition: all 0.2s ease-in-out;
      color: #333;

      &:focus, &:hover {
        color: #333;
      }
    }

    .navbar-form {
      margin: 20px 0;
      transition: all 0.2s ease-in-out;
    }

    .navbar-nav > li > a {
      padding: 35px 20px 30px; //37px 20px;
    }

    &.mb-navbar-light {
      .navbar-nav > li > a {
        color: #ddd;
      }

      .navbar-nav > li > a:hover, 
      .navbar-nav > li > a:focus, 
      .navbar-nav > li.active > a, 
      .navbar-nav > li.active > a:hover, 
      .navbar-nav > li.active > a:focus {
        color: #fff;
      }
    }

    .navbar-brand svg, img {
      &.affix {
        opacity: 0.9 !important;
        transition: opacity 0.2s ease-in-out;
      }
    }
  }

  // Top bar
  .mb-navbar-extention-top {
    background: $primary-color;

    .navbar-nav > li {

      &:after {
        border-width: 0 !important;
      }

      > a, 
      > span {
        display: block;
        padding: 7px 10px;
        font-size: 11px;
        font-weight: 500;
        line-height: 16px;
        color: #fff;
      }

      &.active >a,
      &.active >a:hover,
      &.active >a:focus,
      > a:hover {
        color: #fff;
        background: lighten($primary-color, 10%);
      }
    }
  }

  // Top bar
  .mb-navbar-extention-bottom {
    background: $primary-color-transparent;
    clear: both;
    text-align: center;

    a {
      display: inline-block;
      padding: 7px 10px;
      font-size: 11px;
      font-weight: 500;
      line-height: 16px;
      color: #fff;
      margin: 5px;

      &.active >a,
      &.active >a:hover,
      &.active >a:focus,
      > a:hover {
        color: #fff;
        background: lighten($primary-color, 10%);
      }
    }
  }

  .navbar-header {
    min-height: 100px;
  }
  // Toggle
  .navbar-toggle {
    margin: 32px 15px;
    padding: 10px 10px 10px 10px;
    border: none;
    background: $primary-color;
    position: absolute;
    right: 0;
    // background: transparent !important;

    .icon-bar {
      width: 22px;
      transition: all 0.2s;
      margin-left: 2px;
      margin-right: -4px;
    }
    .top-bar {
      transform: rotate(45deg);
      transform-origin: 10% 10%;
    }
    .middle-bar {
      opacity: 0;
    }
    .bottom-bar {
      transform: rotate(-45deg);
      transform-origin: 10% 90%;
    }

    &.collapsed {

      .icon-bar {
        margin-left: 0px;
        margin-right: -2px;
      }
      .top-bar {
        transform: rotate(0);
      }
      .middle-bar {
        opacity: 1;
      }
      .bottom-bar {
        transform: rotate(0);
      }
    }

    &:hover, &:active, &:focus  {
      background: $primary-color;
      // background: transparent !important;
      .icon-bar {
        background: #fff;
      }
    }

    .icon-bar {
      height: 2px;
      border-radius: 0;
      background: #fff;
    }
  }   

  .navbar-brand { 
    padding: 16px 8px;
    height: auto;
    line-height: 37px;
    font-size: 24px;
    transition: all 0.2s ease-in-out;
  }

  .navbar-nav > li > a, 
  .navbar-nav > li > span {
    display: block;
    margin: 0;
    vertical-align: middle;
    vertical-align:middle;
    text-decoration: none;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 14px;
    padding: 50px 20px 35px;
    color: #333;
    -webkit-transition: all 0.2s ease-out;
    -moz-transition: all 0.2s ease-out;
    -o-transition: all 0.2s ease-out;
    -ms-transition: all 0.2s ease-out;
    transition: all 0.2s ease-out;
  }

  .navbar-nav > .active > a, 
  .navbar-nav > li > a:hover, 
  .navbar-nav > li > a:focus, 
  .navbar-nav > .active > a:hover, 
  .navbar-nav > .active > a:focus {
    outline: none;
    background: none;
    color: $primary-color;
  }

  &.mb-navbar-lines {

    .navbar-nav > li:after {
      display: block;
      content: '';
      border-bottom: solid 2px transparent;
    }

    &.affix-top .navbar-nav > li:after {
      border-bottom: solid 3px transparent;
    }

    .navbar-nav > li.active:after {
      border-color: $primary-color;
    }

    .navbar-nav > li:hover:after {
      border-color: #ccc;
      transition: all 200ms ease-in-out;
    }
  }

  &.mb-navbar-lines-center {

    .navbar-nav > li:after {
      display: block;
      content: '';
      border-bottom: solid 3px #ccc;  
      transform: scaleX(0.0001);  
      transition: transform 250ms ease-in-out;
    }

    .navbar-nav > li.active:after {
      border-color: $primary-color;
    }

    .navbar-nav > li.active:after,
    .navbar-nav > li:hover:after {
      transform: scaleX(1);
    }
  }

  nav ul.dropdown-menu li {
    float: none;

    a {
      font-size: 13px;
    }
  }


  .navbar-brand { 
    margin-left: 0 !important;
    font-size: 22px;
    
    outline: none !important;

    // &.navbar-brand-menu:focus svg, &.navbar-brand-menu:hover svg,
    // &.navbar-brand-menu:focus img, &.navbar-brand-menu:hover img { 
    &:focus svg, &:hover svg,
    &:focus img, &:hover img { 
      // transform: scale(1.15) !important;  
      // transition: all 0.3s ease-in-out;
    }

    &.navbar-brand svg, &.navbar-brand img {
      transform: scale(1);
      transition: all 0.3s ease-in-out;
    }

    // &.navbar-brand svg, &.navbar-brand img {
    //   opacity: 0;
    //   display: none;
    //   transition: all 0.2s ease-in-out;
    // }

    // &.navbar-brand {
    //   opacity: 0.9;
    //   display: block;
    //   transition: all 0.2s ease-in-out;
    // }
  }
  

  .navbar-collapse {
    // padding: 0;
    border-top: 0;

    &.collapsing, &.in {
      background: #fff; 

      ~ .container {
        background: #fff !important;         
      }
    }
  }

  // Form
  .navbar-form {
    margin: 0;
    padding: 0;
    transition: all 0.2s ease-in-out;

    .input-field {
      display: inline-block;
      width: 0px;
      margin-right: 0px;
      transition: all 0.2s linear;

      &.in {
        width: auto;
        margin-right: 5px;
        transition: all 0.2s linear;
      }

      input {
        margin-bottom: 9px;
      }
    }
  }

  // Submenu items

  .dropdown-menu {
    border: none;
    font-size: 13px;

    li a {
      padding: 4px 20px;
      i {
        margin: 3px 0 0;
      }
    }

    .divider {
      margin: 5px 0;
    }
  }

  // .dropdown-menu > li.kopie > a {
  //   padding-left: 5px;
  // }
   
  .dropdown-submenu {
    position: relative;
  }

  .dropdown-submenu > .dropdown-menu {
     top: 0;
     left: 100%;
     margin-top: -6px;
     margin-left: -1px;

         border: none;

    li a i {
      margin: 3px 0 0;
    }

    .divider {
      margin: 5px 0;
    }
     // -webkit-border-radius:0 6px 6px 6px;-moz-border-radius:0 6px 6px 6px;border-radius:0 6px 6px 6px;
   }
    
  .dropdown-submenu > a:after {
    border-color: transparent transparent transparent #333;
    border-style: solid;
    border-width: 5px 0 5px 5px;
    content: " ";
    display: block;
    float: right;  
    height: 0;     
    margin-right: -10px;
    margin-top: 5px;
    width: 0;
  }
   
  .dropdown-submenu:hover>a:after {
    border-left-color:#555;
  }

  .dropdown-menu > li > a:hover { 
    color: $primary-color;
  }  

  .dropdown-menu > .active > a:hover {
    color: #fff;
  }  
}


@media (max-width: 767px) {

  .mb-navbar {
    min-height: 100px;

    .navbar-collapse {
      border-top: 1px solid #ccc;
    }

    &.affix-top .navbar-nav > li > a,
    .navbar-nav > li > a {
      padding: 10px 0;

      .caret {
        display: none;
      }
    }

    &.affix-top .navbar-brand,
    .navbar-brand { 
      padding: 16px 14px;
    }

    .navbar {
      // background-color: #fff;
    }
    .navbar .navbar-nav {
      margin: 0;
    }

    .navbar-nav  {
       display: inline;
    }
    .navbar-default .navbar-brand {
      display: inline;
    }
    .navbar-default .navbar-toggle .icon-bar {
      background-color: #fff;
    }
    .navbar-default .navbar-nav .dropdown-menu > li > a {
      color: red;
      background-color: #ccc;
      border-radius: 4px;
      margin-top: 2px;   
    }
    .navbar-default .navbar-nav .open .dropdown-menu > li > a {
      color: #333;
    }
    .navbar-default .navbar-nav .open .dropdown-menu > li > a:hover,
    .navbar-default .navbar-nav .open .dropdown-menu > li > a:focus {
      background-color: #ccc;
    }
    .navbar-nav .open .dropdown-menu {
      border-bottom: 1px solid white; 
      border-radius: 0;
    }
    .dropdown-menu {
        padding-left: 10px;
    }
    .dropdown-menu .dropdown-menu {
        padding-left: 20px;
    }
    .dropdown-menu .dropdown-menu .dropdown -menu {
      padding-left: 30px;
    }
    li.dropdown.open {
      border: 0px solid red;
    }
  }

}
 
@media (min-width: 768px) {
  .mb-navbar {

    .container {
      padding-left: 0;
      padding-right: 0;
    }

    .navbar-nav {
      float: right;

      > li:last-child {
        > a, > span {
          padding-right: 0;
        }
      }
    }

    ul.nav li:hover > ul.dropdown-menu {
      display: block;
    }
    #navbar {
      text-align: center;
    }
  }
}  

@media(min-width:768px) and (max-width:991px){
  .mb-navbar {
    text-align: center;

    .container {
      line-height: 0;

      .navbar-header {
        text-align: center;
        width: 100%;
        min-height: 86px;

        .navbar-brand {
          float: none;
          display: inline-block;
          padding-bottom: 0;
        }
      }

      &.affix-top .navbar-nav > li > a,
      .navbar-nav > li > a {
        padding: 15px 20px 25px;
      }

      .navbar-collapse {
        float: none !important;
        display: inline-block !important;
      }
    }
  }

  .mb-navbar.affix-top .navbar-brand {
    padding-bottom: 0;
  }
}


.mb-docs-sidebar {

  &.affix {
    position: fixed;
    top: 95px;
  }

  .nav > .active:focus > a, 
  .nav > .active:hover > a, 
  .nav > .active > a {
    border-left: 2px solid $primary-color;
    color: $primary-color;
    font-weight: 700;
    padding-left: 18px;
  }

  .nav > li > a {
    color: #767676;
    display: block;
    font-size: 13px;
    font-weight: 500;
    padding: 4px 20px;
  }

  .nav li a i {
    margin: 5px 0 0;
  }

  .nav > .active > ul {
    display: block;
  }

  .nav {
    .nav {
      display: none;
      padding-bottom: 10px;
    }

    .nav > .active:focus > a, 
    .nav > .active:hover > a, 
    .nav > .active > a {
      font-weight: 500;
      padding-left: 28px;
    }
    
    .nav > li > a {
      font-size: 12px;
      padding-left: 30px;
    }
  }
}