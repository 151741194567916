.timeline-item {

  &:before {
    content: "";
    position: absolute;
    width: 2px;
    background: $primary-color;
    top: 0px;
    left: 23px;
    bottom: 0px;
  }

  &.first {
    &:before {
      top: 18px;
    }
  }

  .timeline-dot {

    h1, h2, h3, h4, h5 {
      margin-top: 0;
    }

    &:before {
      content: "";
      position: absolute;
      width: 16px;
      height: 16px;
      border-radius: 50%;
      border: 2px solid #fff;
      background: $primary-color;
      left: 16px;
      top: 7px;
    }
  } 
}