/* Font */

// &.quote-large {
//   font-size: 50px;
//   line-height: 75px;
// }


// .sc-font-white {
//   color: #fff;
// }

/* Links */

a {
  &.sc-read-more {
    &:before {
      font-family: FontAwesome;
      font-size: 14px;
      content: "\f101";//"»";
      padding-right: 2px;
      color: $primary-color;
      width: 12px;
      display: inline-block;
    }

    &:hover:not(.btn-primary, .sc-color-white):before {
      color: $primary-color-light;
    }

    &.sc-color-white:before,
    &.btn-primary:before {
      color: #fff;
    }
  }
}

/* Misc */

.sc-line-height-0 {
  line-height: 0;
}

.sc-anchor {
  position: relative;
  top: -59px;
  height: 0;
  //width: 0;
}

.sc-normal-case {
  text-transform: none;
}

.sc-soft-corner {
  border-radius: 2px;
}

.sc-zoom {
  overflow: hidden;

  >, > .card-image {
    overflow: hidden;

    img {
      transform: scale(1);
      transition: all 1s ease-in-out 0s;
    }
  }

  &:hover >, &:hover > .card-image {
    img {
      transform: scale(1.1);// rotate(-2deg);
      transition: all 0.5s ease-in-out 0s;
    }
  }

}

.sc-zoom-up,
.sc-zoom-down {
  overflow: hidden;

  > .card-image {
    overflow: hidden;

    img {
      transform: scale(1);
      transition: all 1s ease-in-out 0s;
    }
  }

  &:hover > .card-image img {
    transition: all 0.5s ease-in-out 0s;
  }
}

.sc-zoom-up:hover > .card-image img {
  transform: scale(1.07) rotate(-1.5deg);
}

.sc-zoom-down:hover > .card-image img {
  transform: scale(1.07) rotate(1.5deg);
}

.sc-border-1-1000 {
  border-top: 1px solid #ccc;
}

.sc-border-2-0010 {
  border-bottom: 2px solid #ccc;
}

.sc-border-2-1000 {
  border-top: 2px solid #ccc;
}

.sc-border-3-1000 {
  border-top: 3px solid $primary-color;
}

.sc-border-3-1010 {
  border-top: 3px solid $primary-color;
  border-bottom: 3px solid $primary-color;
}

/* CSS Table Cols*/

/* Align middle */
.sc-middle-wrapper {
  display: table;
  width: 100%;
  height: 100%;
}

.sc-middle {
  display: table-cell; 
  vertical-align: middle;
}

/* Equal height */

.sc-table-row {
  display: table;
  width: 100%;
}

.sc-table-col,
.sc-table-col-25,
.sc-table-col-33,
.sc-table-col-50,
.sc-table-col-66,
.sc-table-col-75 {
  display: table-cell; 
}

.sc-table-col-25 { width: 25% !important; }
.sc-table-col-33 { width: 33% !important; }
.sc-table-col-50 { width: 50% !important; }
.sc-table-col-66 { width: 66% !important; }
.sc-table-col-75 { width: 75% !important; }

/* Colors & Backgrounds */

.sc-color-primary   { color: $primary-color !important; }
.sc-color-primary-light { color: $primary-color-light !important; }
.sc-color-secondary   { color: $secondary-color !important; }
.sc-color-secondary-light { color: $secondary-color-light !important; }
.sc-color-white     { color: #fff !important; }
.sc-color-lightgray { color: #f5f5f5 !important; }
.sc-color-gray      { color: #bbb !important; }
.sc-color-darkgray  { color: #666 !important; }
.sc-color-black     { color: #000 !important; }

.sc-background-white     { background: #fff !important; }
.sc-background-lightergray { background-color: #f6f6f6 !important; }
.sc-background-lightgray { background-color: $gray-8 !important; }
.sc-background-gray      { background-color: #ccc !important; }
.sc-background-darkgray  { background-color: #333 !important; }
.sc-background-primary   { background: $primary-color !important; }
.sc-background-primary-light { background: $primary-color-light !important; }
.sc-background-primary-lighter { background: #b35454 !important; }
.sc-background-primary-dark { background: $primary-color-dark !important; }
.sc-background-secondary   { background: $secondary-color !important; }
.sc-background-secondary-light { background: $secondary-color-light !important; }

.sc-background-primary-opacity       { background: rgba($primary-color, .8) !important; }
.sc-background-primary-light-opacity { background: rgba($primary-color-light, .8) !important; }
.sc-background-darkgray-opacity      { background: rgba(22,22,22, .8) !important; }
.sc-background-black-opacity         { background: rgba(0,0,0, .8) !important; }
.sc-background-lightgray-opacity     { background: rgba(155,155,155, .5) !important; }
.sc-background-white-opacity         { background: rgba(255,255,255, .5) !important; }

.sc-background-image-100,
.sc-background-image {
  background-repeat: no-repeat;
  background-position: center; 
  background-size: cover;
}

.sc-background-image-100 {
  width: 100%;
  height: 100%;
}

/* height */

header[class*='sc-min-height-'], div[class*='sc-min-height-'] {  
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;
}

.sc-min-height-full {
  height: 100%;
  min-height: 400px;
}

.sc-min-height-100 { min-height: 100px; }
.sc-min-height-200 { min-height: 200px; }
.sc-min-height-300 { min-height: 300px; }
.sc-min-height-350 { min-height: 350px; }
.sc-min-height-400 { min-height: 400px; }
.sc-min-height-500 { min-height: 500px; }
.sc-min-height-600 { min-height: 600px; }
.sc-min-height-700 { min-height: 700px; }
.sc-min-height-800 { min-height: 800px; }

.sc-height-200 { height: 200px; }
.sc-height-300 { height: 300px; }
.sc-height-400 { height: 400px; }

.sc-width-210 { width: 210px; }

/* border */

.sc-border-10-white { border: 10px solid #fff; }
.sc-border-10-darkgray { border: 10px solid #333; }
.sc-border-10-primary { border: 10px solid $primary-color; }
.sc-border-10-secondary { border: 10px solid $secondary-color; } 


/* Padding */

.sc-padding-0 { padding: 0px !important; }
.sc-padding-0-1000 { padding-top: 0px !important; }
.sc-padding-0-0100 { padding-right: 0px !important; }
.sc-padding-0-0010 { padding-bottom: 0px !important; }
.sc-padding-0-0001 { padding-left: 0px !important; }
.sc-padding-0-0111 { padding-right: 0px !important; padding-bottom: 0px !important; padding-left: 0px !important; }
.sc-padding-0-1010 { padding-top: 0px !important; padding-bottom: 0px !important;}
.sc-padding-0-0101 { padding-right: 0px !important; padding-left: 0px !important;}

.sc-padding-5 { padding: 5px !important; }
.sc-padding-5-1000 { padding-top: 5px !important; }
.sc-padding-5-0100 { padding-right: 5px !important; }
.sc-padding-5-0010 { padding-bottom: 5px !important; }
.sc-padding-5-0001 { padding-left: 5px !important; }
.sc-padding-5-0111 { padding-right: 5px !important; padding-bottom: 5px !important; padding-left: 5px !important; }
.sc-padding-5-1010 { padding-top: 5px !important; padding-bottom: 5px !important;}
.sc-padding-5-0101 { padding-right: 5px !important; padding-left: 5px !important;}

.sc-padding-10 { padding: 10px !important; }
.sc-padding-10-1000 { padding-top: 10px !important; }
.sc-padding-10-0100 { padding-right: 10px !important; }
.sc-padding-10-0010 { padding-bottom: 10px !important; }
.sc-padding-10-0001 { padding-left: 10px !important; }
.sc-padding-10-0111 { padding-right: 10px !important; padding-bottom: 10px !important; padding-left: 10px !important; }
.sc-padding-10-1010 { padding-top: 10px !important; padding-bottom: 10px !important;}
.sc-padding-10-0101 { padding-right: 10px !important; padding-left: 10px !important;}
.sc-padding-10-1110 { padding-top: 10px !important; padding-right: 10px !important; padding-bottom: 10px !important;}

.sc-padding-15-1000 { padding-top: 15px !important; }

.sc-padding-20 { padding: 20px !important; }
.sc-padding-20-1000 { padding-top: 20px !important; }
.sc-padding-20-0100 { padding-right: 20px !important; }
.sc-padding-20-0010 { padding-bottom: 20px !important; }
.sc-padding-20-0001 { padding-left: 20px !important; }
.sc-padding-20-0111 { padding-right: 20px !important; padding-bottom: 20px !important; padding-left: 20px !important; }
.sc-padding-20-1101 { padding-top: 20px !important; padding-right: 20px !important; padding-left: 20px !important; }
.sc-padding-20-1010 { padding-top: 20px !important; padding-bottom: 20px !important;}
.sc-padding-20-0101 { padding-right: 20px !important; padding-left: 20px !important;}

.sc-padding-30 { padding: 30px  !important; }
.sc-padding-30-1000 { padding-top: 30px !important; }
.sc-padding-30-0100 { padding-right: 30px !important; }
.sc-padding-30-0010 { padding-bottom: 30px !important; }
.sc-padding-30-0001 { padding-left: 30px !important; }
.sc-padding-30-0111 { padding-right: 30px !important; padding-bottom: 30px !important; padding-left: 30px !important; }
.sc-padding-30-1010 { padding-top: 30px !important; padding-bottom: 30px !important;}
.sc-padding-30-0101 { padding-right: 30px !important; padding-left: 30px !important;}

.sc-padding-40 { padding: 40px  !important; }
.sc-padding-40-1000 { padding-top: 40px !important; }
.sc-padding-40-0100 { padding-right: 40px !important; }
.sc-padding-40-0010 { padding-bottom: 40px !important; }
.sc-padding-40-0001 { padding-left: 40px !important; }
.sc-padding-40-0111 { padding-right: 40px !important; padding-bottom: 40px !important; padding-left: 40px !important; }
.sc-padding-40-1010 { padding-top: 40px !important; padding-bottom: 40px !important;}
.sc-padding-40-0101 { padding-right: 40px !important; padding-left: 40px !important;}

.sc-padding-50 { padding: 50px  !important; }
.sc-padding-50-1010 { padding-top: 50px !important; padding-bottom: 50px !important;}
.sc-padding-50-1000 { padding-top: 50px !important; }

.sc-padding-60 { padding: 60px !important; }

.sc-margin-0  { margin: 0px  !important; }
.sc-margin-10 { margin: 10px  !important; }
.sc-margin-10-0010 { margin-bottom: 10px !important; }
.sc-margin-10-0111 { margin-right: 10px !important; margin-bottom: 10px !important; margin-left: 10px !important; }
.sc-margin-10-1110 { margin-right: 10px !important; margin-bottom: 10px !important; margin-top: 10px !important; }
.sc-margin-10-1010 { margin-top: 10px !important; margin-bottom: 10px !important; }
.sc-margin-10-0110 { margin-right: 10px !important; margin-bottom: 10px !important; }
.sc-margin-20 { margin: 20px  !important; }
.sc-margin-20-1000 { margin-top: 20px !important;}
.sc-margin-20-0010 { margin-bottom: 20px !important; }
.sc-margin-20-0001 { margin-left: 20px !important; }
.sc-margin-20-1010 { margin-top: 20px !important; margin-bottom: 20px !important; }
.sc-margin-30 { margin: 30px  !important; }
.sc-margin-30-1000 { margin-top: 30px !important; }
.sc-margin-30-0010 { margin-bottom: 30px !important; }
.sc-margin-40-1010 { margin-top: 40px !important; margin-bottom: 40px !important; }
.sc-margin-50-0010 { margin-bottom: 50px !important; }
.sc-margin-50-1000 { margin-top: 50px !important; }
.sc-margin-60-0010 { margin-bottom: 60px !important; }
.sc-margin-80-0010 { margin-bottom: 80px !important; }
.sc-margin-110-0010 { margin-bottom: 110px !important; }

/* Responsive */

@media(max-width:767px) {
  .sc-left-sm,
  .sc-right-sm {
    float: none !important;
  }
  .sc-padding-0-sm { 
    padding: 0px !important; 
  }
}

@media(min-width:768px) and (max-width:991px){
  .sc-responsive-size {
    h1 { font-size: 26px; }
    h2 { font-size: 20px; }
    h3 { font-size: 20px; }
    h4 { font-size: 18px; }
    p  { font-size: 14px; }
  }
  .sc-padding-0-sm { 
    padding: 0px !important; 
  }
}

@media(min-width:768px){
  .sc-left-sm {
    float: left;
  }
  .sc-right-sm {
    float: right;
  }
}
