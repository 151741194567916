.pagination {

  >li>a, >li>span {
    color: $primary-color;
    font-weight: 600;
  }

  >li>a, >li>span, >.disabled>a, >.disabled>a:focus, >.disabled>a:hover, >.disabled>span, >.disabled>span:focus, >.disabled>span:hover {
    border-color: $primary-color;
  }

  >.disabled>a, >.disabled>a:focus, >.disabled>a:hover, >.disabled>span, >.disabled>span:focus, >.disabled>span:hover {
    color: lighten($primary-color, 10);
  }

  >li:not(:first-child)>a {
    border-left: none;
  }

  >.active>a, >.active>a:focus, >.active>a:hover, >.active>span, >.active>span:focus, >.active>span:hover {
    background-color: darken($primary-color, 8%);
    border-color: $primary-color; 
    -webkit-box-shadow: inset 0 3px 5px rgba(0,0,0,.125);
    box-shadow: none;
  }

  >li>a:focus, >li>a:hover, >li>span:focus, >li>span:hover {
    color: #fff;
    background-color: $primary-color;
    border-color: $primary-color;
  }
}