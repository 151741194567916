// based on this solution
// Author: Marko Švaljek
// Source: http://codepen.io/msval/pen/pCvzA

.windmill {

  .mill {
    width: 30px;
    height: 35px;
    margin: 1px auto 0;
    position: relative;
    z-index: 1; // -10
    // background: #222;

    .post {
      position: absolute;
      width: 2px;
      height: 20px;
      background: linear-gradient(to top, #c6c6c6 0%, #666 100%);
      top: 14px;
      left: 14px;
      border-radius: 3px;
    }

    .engine {
      position: absolute;
      width: 4px;
      height: 3px;
      background: linear-gradient(to bottom, #bababa 0%, #878787 100%);
      top: 13px;
      left: 13px;
      border-radius: 2px;
    }

    .head {
      width: 1px;
      height: 1px;
      position: absolute;
      top: 13px;
      left: 14px;
      background: #aaa;
      border-radius: 50%;
    }

    .flaps {
      transform-origin: 15px 14px;
      transition: all 0.2s ease-out;

      .rotation {
        width: 20px;
        height: 20px;
        position: absolute;
        top: 4px;
        left: 5px;

        &.clockwise {
          animation: rotatemill-clockwise 16s linear infinite;
        }

        &.counter-clockwise {
          animation: rotatemill-counter-clockwise 16s linear infinite;
        }

        .flap {
          width: 1px;
          height: 12px;
          position: absolute;
          background: linear-gradient(to bottom, #bababa 0%, #878787 100%);
          border-top-left-radius: 5px 20px;

          &:nth-child(1) {
            top: -2px;
            left: 10px;
          }

          &:nth-child(2) {
            top: 7px;
            left: 15px;
            transform: rotate(120deg);
          }

          &:nth-child(3) {
            top: 7px;
            left: 4px;
            transform: rotate(240deg);
          }
        }
      }
    }

    

    // .bulb {
    //   height: 10px;
    //   width: 10px;
    //   position: absolute;
    //   top: 123px;
    //   left: 145px;
    //   background: #360A0A;
    //   border-radius: 50%;
    //   animation: lightbulblight 2s linear infinite;
    //   z-index: -1;
    // }
  }

 @keyframes "rotatemill-clockwise" {
    100% {
      transform: rotate(0deg);
    }
    0% {
      transform: rotate(-360deg);
    }
  }

  @keyframes "rotatemill-counter-clockwise" {
    100% {
      transform: rotate(0deg);
    }
    0% {
      transform: rotate(360deg);
    }
  }

 
// @keyframes "lightbulblight" {
//   0% {
//     background: #360A0A;
//     box-shadow: 0 0 0 #360A0A;
//   }
//   50% {
//     background: #e98989;
//     box-shadow: 0 0 10px #e98989;
//   }
// }

}

.mb-navbar {
  &.affix-top {
    .windmill {
      margin-top: 20px;
      transition: all 0.2s ease-in-out;
    }
  }

  .windmill {
    margin-top: 5px;

    .charge {
      background: $primary-color;
    }
  }
}