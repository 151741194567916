@charset "UTF-8";
/*** THEME ***/
/*** MATERIALIZE ***/
/*** Colors ***/
/*** Badges ***/
/*** Buttons ***/
/*** Cards ***/
/*** Collapsible ***/
/*** Chips ***/
/*** Date Picker ***/
/*** Dropdown ***/
/*** Fonts ***/
/*** Forms ***/
/*** Global ***/
/*** Navbar ***/
/*** SideNav ***/
/*** Photo Slider ***/
/*** Spinners | Loaders ***/
/*** Tabs ***/
/*** Tables ***/
/*** Toasts ***/
/*** Typography ***/
/*** Collections ***/
/* Progress Bar */
.dev {
  background: purple;
  color: white;
  padding: 5px 10px;
  font-weight: 700;
  font-size: 12px;
  display: inline-block;
}

body {
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  color: #3D3D3D;
}

h3, h4, h5, h6 {
  font-weight: 800;
}

.is-title {
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  line-height: 1.4;
}

.size-xxl {
  font-size: 33px;
}

.size-xl {
  font-size: 24px;
}

.size-l {
  font-size: 22px;
}

.size-m {
  font-size: 18px;
}

.size-s {
  font-size: 15px;
  font-weight: 700;
}

.size-xs {
  font-size: 13px;
  font-weight: 700;
}

h1.hero {
  font-size: 45px;
  font-weight: 700;
  line-height: 1.1;
  margin-bottom: 20px;
}

h2.hero {
  font-size: 26px;
  font-weight: 700;
  line-height: 1.1;
  text-transform: none;
}

.btn {
  text-transform: uppercase;
  font-weight: 600;
}

.lead {
  font-size: 17px;
  font-weight: 400;
}

.breadcrumb li {
  text-transform: uppercase;
  font-weight: 600;
  color: #a0a0a0;
}

.breadcrumb li a {
  padding: 20px 0;
  display: inline-block;
  color: #3D3D3D;
}

.breadcrumb li a:hover, .breadcrumb li a:focus {
  color: #fc7174;
}

.stroke {
  text-decoration: line-through;
}

.checkmark {
  display: inline-block;
  padding: 10px;
}

.checkmark:after {
  content: '';
  display: block;
  width: 12px;
  height: 27px;
  border: solid #fc7174;
  border-width: 0 3px 3px 0;
  transform: rotate(45deg);
}

#return-to-top {
  position: fixed;
  z-index: 5;
  bottom: 120px;
  right: 20px;
  background: #9e4547;
  background: rgba(158, 69, 71, 0.85);
  width: 50px;
  height: 50px;
  display: block;
  text-decoration: none;
  border-radius: 35px;
  display: none;
  text-align: center;
  transition: background, padding 0.3s ease;
}

#return-to-top i {
  color: #fff;
  transition: background, padding 0.3s ease;
  font-size: 35px;
  padding-top: 9px;
}

#return-to-top:hover {
  background: #9e4547;
}

#return-to-top:hover i {
  color: #fff;
  padding-top: 5px;
}

@media (max-width: 767px) {
  #return-to-top {
    display: none !important;
  }
}

/* October */
.stripe-loading-indicator .stripe, .stripe-loading-indicator .stripe-loaded {
  height: 3px !important;
  background: #fc7174 !important;
}

/* Home */
#searchWizard.carousel {
  background-repeat: no-repeat;
  background-size: cover;
}

#searchWizard.carousel * {
  text-shadow: none !important;
}

#searchWizard.carousel .carousel-indicators {
  display: none;
  position: absolute;
  left: 50%;
  top: 60px;
  width: 507px;
  height: 24px;
  padding-left: 0;
  margin-left: -253px;
  text-align: center;
  list-style: none;
}

#searchWizard.carousel .carousel-indicators .active {
  width: 24px;
  height: 24px;
  background-color: #fc7174;
}

#searchWizard.carousel .carousel-indicators li {
  display: inline-block;
  width: 24px;
  height: 24px;
  margin: 0 25px;
  text-indent: -999px;
  cursor: pointer;
  background-color: transparent;
  border: 2px solid #fc7174;
  border-radius: 12px;
  position: relative;
}

#searchWizard.carousel .carousel-indicators li:not(:last-child):before {
  content: "";
  border-bottom: 2px solid #fc7174;
  position: absolute;
  top: 10px;
  width: 27px;
  left: 22px;
  z-index: 16;
}

#searchWizard.carousel .carousel-indicators li:not(:first-child):after {
  content: "";
  border-bottom: 2px solid #fc7174;
  position: absolute;
  top: 10px;
  width: 27px;
  right: 22px;
  z-index: 16;
}

#searchWizard.carousel .carousel-caption {
  top: 120px;
  left: 0;
  right: 0;
}

#searchWizard.carousel .item {
  min-height: 430px;
}

#searchWizard.carousel .item > img {
  height: 430px;
  width: 2200px;
}

#searchWizard.carousel .widget-x-image {
  border: 1px solid #fff;
  border-radius: 3px;
  margin: 5px auto;
}

#searchWizard.carousel .widget-0 .carousel-caption {
  top: 80px;
  min-height: 350px;
}

#searchWizard.carousel .widget-0 .carousel-caption .widget-0-top {
  min-height: 350px;
}

#searchWizard.carousel .widget-0 .carousel-caption .widget-0-top .widget-0-top-layer {
  background: rgba(0, 0, 0, 0.8);
  padding: 30px;
  min-height: 260px;
}

@media (max-width: 768px) {
  #searchWizard.carousel .item > img {
    height: 530px;
  }
  #searchWizard.carousel .widget-0 .carousel-caption {
    top: 110px;
  }
  #searchWizard.carousel .widget-0 .carousel-caption .widget-0-top {
    min-height: auto;
  }
  #searchWizard.carousel .widget-0 .carousel-caption .widget-0-bottom {
    padding-bottom: 100px;
  }
  #searchWizard.carousel .size-xxl {
    font-size: 18px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .home-blogentry-latest {
    height: 74px;
    overflow: hidden;
    margin-bottom: 10px;
  }
}

/* Blog */
.blog .blog-item {
  background: #eaeaea;
  margin-bottom: 30px;
}

.blog .blog-item h3, .blog .blog-item h4 {
  padding: 20px 25px 0px;
  margin-bottom: 5px;
  font-weight: 800;
  height: 70px;
  line-height: 26px;
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
}

.blog .blog-item h3:hover, .blog .blog-item h4:hover {
  color: #fc7174;
}

.blog .blog-item p {
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  font-size: 15px;
  padding: 5px 25px 10px;
  color: #3D3D3D;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.blog .blog-item a.blog-link {
  display: block;
  font-weight: 600;
  width: 100%;
  padding: 20px 25px;
  text-transform: uppercase;
  color: #fb3f43;
}

.blog .blog-item a.blog-link:hover {
  text-decoration: underline;
}

.blog .blog-item .blog-no-image {
  width: 100%;
  padding: 25%;
  background: #a0a0a0;
  position: relative;
  display: inline-table;
}

.blog .blog-item .blog-no-image > span {
  color: #eaeaea;
  font-size: 17px;
  font-weight: 600;
  position: absolute;
  top: 30px;
  left: 25px;
}

.blog .blog-item.blog-item-small h3, .blog .blog-item.blog-item-small h4 {
  padding: 15px 20px 0px;
  margin: 0px;
}

.blog .blog-item.blog-item-small p {
  padding: 0px 20px 5px;
  font-size: 13px;
}

.blog .blog-item.blog-item-small a.blog-link-small {
  padding: 15px 20px;
  font-size: 14px;
}

.blog-content {
  margin-top: 20px;
}

.blog-content h1, .blog-content h2 {
  color: #f00;
}

.blog-content a {
  font-weight: 600;
}

.blog-content a:hover {
  color: #fc7174;
  text-decoration: underline;
}

.blog-content h3, .blog-content h4, .blog-content h5 {
  padding: 15px 0px 10px;
  margin: 0px;
  font-size: 18px;
  color: #888;
  font-weight: 600;
}

.blog-content ul {
  list-style: none;
  padding: 0;
  margin-left: 15px;
}

.blog-content ul li {
  padding-left: 5px;
  text-indent: 0px;
  line-height: 24px;
}

.blog-content ul li:before {
  content: "●";
  padding-right: 2px;
  color: #fc7174;
  margin-left: -20px;
  font-size: 18px;
  width: 20px;
  display: inline-block;
}

@media (min-width: 992px) {
  .blog .blog-item h3, .blog .blog-item h4 {
    height: auto;
  }
}

@media (max-width: 991px) {
  .blog .blog-item h3, .blog .blog-item h4 {
    height: 95px;
  }
}

/* Search Musterküchen */
#searchNavi {
  box-shadow: inset 0 -1px 0px #fff;
  /* fix background image retina half line */
}

#searchNavi div.searchHint {
  position: absolute;
  left: 40px;
  top: 50px;
  display: none;
  z-index: 1;
}

#searchNavi ul.searchNaviOptions {
  list-style: none;
  padding: 0;
  margin: 0;
}

#searchNavi ul.searchNaviOptions > li {
  float: left;
  padding: 0;
  margin: 0;
  position: relative;
  background: #fc7174;
  margin-bottom: 72px;
  width: 150px;
}

#searchNavi ul.searchNaviOptions > li.searchDistance {
  float: right;
  background: #9e4547;
  margin-bottom: 0px;
  z-index: 2;
}

#searchNavi ul.searchNaviOptions > li.searchDistance > div.btn-group > a, #searchNavi ul.searchNaviOptions > li.searchDistance > div.btn-group > span {
  padding: 12px 10px;
  text-align: center;
}

#searchNavi ul.searchNaviOptions > li.searchDistance > div.btn-group > a:before, #searchNavi ul.searchNaviOptions > li.searchDistance > div.btn-group > a:after, #searchNavi ul.searchNaviOptions > li.searchDistance > div.btn-group > span:before, #searchNavi ul.searchNaviOptions > li.searchDistance > div.btn-group > span:after {
  border: none;
}

#searchNavi ul.searchNaviOptions > li.searchDistance > div.btn-group > a a.btn-link, #searchNavi ul.searchNaviOptions > li.searchDistance > div.btn-group > span a.btn-link {
  padding: 4px 0 4px 4px;
  line-height: 1;
}

#searchNavi ul.searchNaviOptions > li.searchDistance .distanceOption {
  display: block !important;
}

#searchNavi ul.searchNaviOptions > li.searchDistance .distanceOption #option-zip {
  display: block !important;
  background: #9e4547;
  border-color: #9e4547;
  z-index: 1;
}

#searchNavi ul.searchNaviOptions > li.searchDistance .distanceOption #option-zip > span {
  padding: 12px 10px;
}

#searchNavi ul.searchNaviOptions > li.searchDistance .distanceOption #option-zip table {
  margin-left: 10px;
  width: 140px;
  margin-top: 4px;
  margin-bottom: 5px;
}

#searchNavi ul.searchNaviOptions > li.searchDistance .distanceOption #option-zip table td {
  padding: 0;
  width: 32px;
}

#searchNavi ul.searchNaviOptions > li > div.btn-group > a, #searchNavi ul.searchNaviOptions > li > div.btn-group > span {
  display: inline-block;
  padding: 12px 15px;
  color: #fff;
  font-weight: 600;
  width: 150px;
  position: relative;
  text-transform: uppercase;
  box-shadow: none;
}

#searchNavi ul.searchNaviOptions > li > div.btn-group > a:before, #searchNavi ul.searchNaviOptions > li > div.btn-group > span:before {
  border-left: 1px solid #fec1c2;
  content: '';
  position: absolute;
  height: 22px;
  left: 0px;
}

#searchNavi ul.searchNaviOptions > li > div.btn-group > a:after, #searchNavi ul.searchNaviOptions > li > div.btn-group > span:after {
  border-left: 1px solid #fec1c2;
  content: '';
  position: absolute;
  height: 22px;
  right: -1px;
}

#searchNavi ul.searchNaviOptions > li > div.btn-group > a:hover .caret, #searchNavi ul.searchNaviOptions > li > div.btn-group > a:focus .caret, #searchNavi ul.searchNaviOptions > li > div.btn-group > a.active .caret, #searchNavi ul.searchNaviOptions > li > div.btn-group > span:hover .caret, #searchNavi ul.searchNaviOptions > li > div.btn-group > span:focus .caret, #searchNavi ul.searchNaviOptions > li > div.btn-group > span.active .caret {
  color: #fff;
}

#searchNavi ul.searchNaviOptions > li > div.btn-group > a .caret, #searchNavi ul.searchNaviOptions > li > div.btn-group > span .caret {
  float: right;
  display: inline-block;
  margin-top: 8px;
  color: #fec1c2;
}

#searchNavi ul.searchNaviOptions > li > div.btn-group .dropdown-menu {
  border-radius: 0;
  min-width: 151px;
  margin-top: -1px;
  border-color: #fc7174;
}

#searchNavi ul.searchNaviOptions > li > div.btn-group .dropdown-menu li a, #searchNavi ul.searchNaviOptions > li > div.btn-group .dropdown-menu li span {
  text-transform: uppercase;
  padding: 0;
}

#searchNavi ul.searchNaviOptions > li > div.btn-group .dropdown-menu li span {
  padding: 5px 15px;
}

#searchNavi ul.searchNaviOptions > li #deleteFilter {
  display: none;
  color: #333;
  position: absolute;
  font-size: 11px;
  text-transform: uppercase;
  font-weight: 600;
  right: 0;
  padding: 1px;
  text-align: center;
  width: 150px;
  top: 46px;
}

#searchNavi ul.searchNaviOptions > li #deleteFilter:hover, #searchNavi ul.searchNaviOptions > li #deleteFilter:focus, #searchNavi ul.searchNaviOptions > li #deleteFilter.active {
  background: #ddd;
}

#searchNavi .force-zip-style {
  background: #fff;
  padding: 12px 15px !important;
  display: block;
}

#searchNavi .force-zip-style:hover, #searchNavi .force-zip-style:focus, #searchNavi .force-zip-style.active {
  background: #fff;
}

#searchNavi .force-zip-style input[type="text"] {
  border: 1px solid #ccc;
  padding: 4px 0px 4px 6px;
  margin-bottom: 5px;
  width: 54px;
  height: 20px;
  float: left;
}

#searchNavi .force-zip-style input[type="text"]:focus {
  border: 1px solid #fc7174 !important;
  box-shadow: none !important;
}

#searchNavi .force-zip-style a {
  display: block;
  float: right;
  width: 50px;
  padding: 3px !important;
}

#searchNavi .distanceOption > li > div {
  padding: 0px 0 4px 10px;
}

#searchNavi .distanceOption > li > div .input-field {
  margin-top: 0;
}

#searchNavi .distanceOption > li > div .input-field label {
  color: #fff;
  font-size: 11px;
  font-weight: 600;
  top: -1px;
}

#searchNavi .distanceOption > li > div .input-field label.active {
  font-size: 9px;
  color: #ddd;
  transform: translateY(-15px);
}

#searchNavi .distanceOption > li > div .input-field .caret {
  color: #fff;
  margin-left: -12px;
  margin-top: 0px;
}

#searchNavi .distanceOption > li > div .input-field .form-control {
  margin-bottom: 8px;
}

#searchNavi .distanceOption > li > div .input-field input[type="text"] {
  color: #fff;
  width: 47px;
  float: left;
  font-size: 11px;
  font-weight: 600;
  height: 18px;
  border-bottom: 1px solid #fc7174;
}

#searchNavi .distanceOption > li > div .input-field input[type="text"]:focus {
  border-color: #fff;
  box-shadow: 0 1px 0 0 #fff;
}

#searchNavi .distanceOption > li > div div.btn-group {
  margin-left: 10px;
}

#searchNavi .distanceOption > li > div div.btn-group.open .dropdown-toggle {
  box-shadow: none;
}

#searchNavi .distanceOption > li > div div.btn-group label {
  cursor: pointer;
}

#searchNavi .distanceOption > li > div div.btn-group input[type="text"] {
  width: 72px;
  cursor: pointer;
  background-color: transparent !important;
}

#searchNavi .distanceOption > li > div div.btn-group .dropdown-menu {
  border-radius: 0;
  min-width: 100%;
  margin-top: -5px;
  border-color: #ccc;
}

#searchNavi .distanceOption > li > div div.btn-group .dropdown-menu li a, #searchNavi .distanceOption > li > div div.btn-group .dropdown-menu li span {
  text-transform: none;
  padding: 3px 6px;
  color: #666;
  font-size: 11px;
  font-weight: 600;
}

#searchNavi .distanceOption > li > div div.btn-group .dropdown-menu li a:hover, #searchNavi .distanceOption > li > div div.btn-group .dropdown-menu li span:hover {
  background: #fc7174;
  color: #fff;
}

#searchNavi .distanceOption > li > div div.btn-group .dropdown-menu li span {
  padding: 5px 15px;
}

#searchNavi .distanceOption > li > div .error-message {
  background: #fb494d;
  color: #fff;
  font-size: 11px;
  font-weight: 600;
  padding: 2px 10px;
  margin-left: -10px;
  display: none;
}

#searchNavi .distanceOption > li > div a.btn {
  padding: 0px 0px 0px 0px;
  display: inline-block;
  float: left;
  font-size: 10px;
  line-height: 18px;
  text-transform: none;
}

#searchNavi .distanceOption > li > div a.btn.btn-link {
  width: 48px;
  margin-right: 10px;
}

#searchNavi .distanceOption > li > div a.btn.btn-primary {
  width: 72px;
}

#searchNavi .searchOption, #searchNavi ._distanceOption {
  list-style: none;
  padding: 0;
  margin: 0;
  position: absolute;
}

#searchNavi .searchOption > li, #searchNavi ._distanceOption > li {
  float: left;
  padding: 0;
  margin: 0;
}

#searchNavi .searchOption > li > div, #searchNavi ._distanceOption > li > div {
  background: #484848;
  border-bottom: 1px solid #ccc;
  border-left: 1px solid #ccc;
  position: relative;
  width: 150px;
  display: none;
}

#searchNavi .searchOption > li > div.active, #searchNavi ._distanceOption > li > div.active {
  display: block;
}

#searchNavi .searchOption > li > div > span, #searchNavi ._distanceOption > li > div > span {
  padding: 4px 0 4px 15px;
}

#searchNavi .searchOption > li > div span, #searchNavi .searchOption > li > div table, #searchNavi .searchOption > li > div label, #searchNavi .searchOption > li > div input, #searchNavi ._distanceOption > li > div span, #searchNavi ._distanceOption > li > div table, #searchNavi ._distanceOption > li > div label, #searchNavi ._distanceOption > li > div input {
  color: #fff;
  text-transform: uppercase;
  font-size: 11px;
  font-weight: 600;
  margin-bottom: 0;
}

#searchNavi .searchOption > li > div input[type="text"], #searchNavi ._distanceOption > li > div input[type="text"] {
  border: 1px solid #ccc;
  padding: 0px 5px;
  background: #b04d4f;
  height: auto;
  margin-bottom: 5px;
  margin-left: 10px;
  width: 70px;
  float: left;
}

#searchNavi .searchOption > li > div input[type="text"]:focus, #searchNavi ._distanceOption > li > div input[type="text"]:focus {
  border: 1px solid #fff !important;
  box-shadow: none !important;
  background: #b85e60;
}

#searchNavi .searchOption > li > div a.btn, #searchNavi ._distanceOption > li > div a.btn {
  padding: 0px 10px 0px 10px;
  display: inline-block;
  position: absolute;
  right: 10px;
  font-size: 14px;
}

#searchNavi .searchOption > li > div a:not(.btn), #searchNavi ._distanceOption > li > div a:not(.btn) {
  color: #ccc;
  padding: 0px 15px 0px 15px;
  display: inline-block;
  position: absolute;
  right: 0px;
  font-size: 14px;
}

#searchNavi .searchOption > li > div a:not(.btn):hover, #searchNavi .searchOption > li > div a:not(.btn):focus, #searchNavi .searchOption > li > div a:not(.btn).active, #searchNavi ._distanceOption > li > div a:not(.btn):hover, #searchNavi ._distanceOption > li > div a:not(.btn):focus, #searchNavi ._distanceOption > li > div a:not(.btn).active {
  color: #fff;
  background: #666;
}

@media (max-width: 480px) {
  #searchNavi .container {
    width: 330px;
  }
}

@media (min-width: 481px) and (max-width: 991px) {
  #searchNavi .container {
    width: 481px;
  }
}

#settingsNavi .settingsNaviView {
  position: relative;
}

#settingsNavi .settingsNaviView > span {
  font-size: 13px;
  font-weight: 700;
  color: #999;
  text-transform: uppercase;
  display: inline-block;
  margin-right: 7px;
}

#settingsNavi .settingsNaviResults h1 {
  font-size: 27px;
  font-weight: 700;
  color: #333;
  line-height: 1.2;
  margin: 0;
  text-align: center;
}

#settingsNavi .settingsNaviResults h1 b {
  font-weight: 700;
  color: #fc7174;
}

#settingsNavi .settingsNaviSort {
  text-align: right;
}

#settingsNavi .settingsNaviSort > span {
  display: inline-block;
  text-transform: uppercase;
  color: #aaa;
  padding: 2px;
  font-size: 13px;
  line-height: 14px;
  font-weight: 700;
}

#settingsNavi .settingsNaviSort .dropdown-menu .fa-spin {
  display: none;
}

#settingsNavi .settingsNaviSort .selection .settingsNaviSortNew {
  display: none;
}

#settingsNavi .settingsNaviSort .btn-group {
  margin-left: 10px;
}

#settingsNavi .settingsNaviSort .btn-group button {
  text-align: left;
  width: 200px;
}

#settingsNavi .settingsNaviSort .btn-group button .caret {
  float: right;
  display: inline-block;
  margin-top: 9px;
}

#settingsNavi .dropdown-menu {
  border-radius: 0;
  min-width: 100%;
  margin-top: -1px;
  border-color: #fc7174;
}

#settingsNavi .dropdown-menu li a, #settingsNavi .dropdown-menu li span {
  text-transform: uppercase;
  padding: 5px 10px;
  color: #333;
}

#settingsNavi .dropdown-menu li span {
  padding: 5px 15px;
}

@media (max-width: 991px) {
  #settingsNavi .settingsNaviView, #settingsNavi .settingsNaviResults, #settingsNavi .settingsNaviPaginierung, #settingsNavi .settingsNaviSort {
    text-align: center;
    padding: 10px 0;
  }
}

#searchResults .col-xs-12 {
  padding-left: 0;
  padding-right: 0;
}

#searchResults .grid.view-list {
  background: #fff;
  max-width: 1170px;
}

#searchResults .grid.view-list .grid-item {
  float: left;
  margin-left: 15px;
  overflow: hidden;
  width: 100%;
  height: 270px;
  border-right: 30px solid #fff;
}

#searchResults .grid.view-list .grid-item .card {
  height: 240px;
  float: left;
}

#searchResults .grid.view-grid {
  background: #fff;
  max-width: 1170px;
}

#searchResults .grid.view-grid:after {
  content: '';
  display: block;
  clear: both;
}

#searchResults .grid.view-grid .grid-item {
  float: left;
  margin-left: 15px;
  overflow: hidden;
}

#searchResults .grid.view-grid .grid-item.gigante {
  background: #9e4547;
  border-bottom: 30px solid #fff;
}

#searchResults .grid.view-grid .grid-item.grid-item-xs {
  width: 100%;
  height: 345px;
  margin-left: 0px;
}

#searchResults .grid.view-grid .grid-item.grid-item-xs.gigante {
  height: 690px;
}

#searchResults .grid.view-grid .grid-item.grid-item-xs .card {
  height: 315px;
}

#searchResults .grid.view-grid .grid-item.grid-item-sm {
  width: 345px;
  height: 345px;
}

#searchResults .grid.view-grid .grid-item.grid-item-sm.gigante {
  height: 690px;
}

#searchResults .grid.view-grid .grid-item.grid-item-sm .card {
  height: 315px;
}

#searchResults .grid.view-grid .grid-item.grid-item-md {
  width: 454px;
  height: 454px;
}

#searchResults .grid.view-grid .grid-item.grid-item-md.gigante {
  height: 908px;
}

#searchResults .grid.view-grid .grid-item.grid-item-md .card {
  height: 424px;
}

#searchResults .grid.view-grid .grid-item.grid-item-lg {
  width: 360px;
  height: 360px;
}

#searchResults .grid.view-grid .grid-item.grid-item-lg.gigante {
  height: 720px;
}

#searchResults .grid.view-grid .grid-item.grid-item-lg .card {
  height: 330px;
}

/* Home */
#resultDetailsCarousel {
  background: #eee;
}

#resultDetailsCarousel * {
  text-shadow: none !important;
}

#resultDetailsCarousel .item {
  width: 100%;
  height: auto;
}

#resultDetailsCarousel .carousel-inner > .item > img {
  min-width: 100%;
  height: auto;
}

.nav.nav-tabs li.active a {
  color: #fc7174;
}

.nav.nav-tabs li:not(.active) a {
  color: #666;
}

.nav.nav-tabs li a {
  font-weight: 600;
  font-size: 14px;
  text-transform: uppercase;
  border-radius: 2px;
}

.modal .modal-content {
  background: #9e4547;
}

#requestForm, #requestSuccess {
  padding: 0;
  margin: 0;
}

#requestForm .close, #requestSuccess .close {
  position: absolute;
  right: 0px;
  top: 0px;
  color: #febcbd;
  text-shadow: none;
  font-size: 42px;
  padding: 10px 15px;
}

#requestForm .close:hover, #requestForm .close:focus, #requestSuccess .close:hover, #requestSuccess .close:focus {
  background-color: transparent;
  color: #fff;
}

#requestForm label, #requestForm input, #requestForm textarea, #requestSuccess label, #requestSuccess input, #requestSuccess textarea {
  color: #fff !important;
}

#requestForm .btn-primary, #requestSuccess .btn-primary {
  padding-left: 40px;
  padding-right: 40px;
}

#requestForm .input-field input[type=text], #requestSuccess .input-field input[type=text] {
  border-color: #febcbd;
}

#requestForm .input-field input[type=text]:focus:not([readonly]), #requestSuccess .input-field input[type=text]:focus:not([readonly]) {
  box-shadow: 0 1px 0 0 #febcbd;
}

#requestForm textarea, #requestSuccess textarea {
  border: none;
  border-bottom: 1px solid #febcbd;
  outline: none;
}

#requestForm textarea:focus, #requestSuccess textarea:focus {
  box-shadow: 0 1px 0 0 #febcbd;
}

#requestForm [type="checkbox"].filled-in + label:after, #requestSuccess [type="checkbox"].filled-in + label:after {
  border-color: #febcbd;
  left: 10px;
}

#requestForm [type="checkbox"].filled-in + label:before, #requestSuccess [type="checkbox"].filled-in + label:before {
  left: 11px;
}

#requestForm.requestFormInline label, #requestForm.requestFormInline input, #requestForm.requestFormInline textarea, #requestSuccess.requestFormInline label, #requestSuccess.requestFormInline input, #requestSuccess.requestFormInline textarea {
  color: #707070 !important;
}

#requestForm.requestFormInline .input-field input[type=text], #requestSuccess.requestFormInline .input-field input[type=text] {
  border-color: #888;
}

#requestForm.requestFormInline .input-field input[type=text]:focus:not([readonly]), #requestSuccess.requestFormInline .input-field input[type=text]:focus:not([readonly]) {
  box-shadow: 0 1px 0 0 #888;
}

#requestForm.requestFormInline textarea, #requestSuccess.requestFormInline textarea {
  border: none;
  border-bottom: 1px solid #888;
}

#requestForm.requestFormInline textarea:focus, #requestSuccess.requestFormInline textarea:focus {
  box-shadow: 0 1px 0 0 #888;
}

#requestForm.requestFormInline [type="checkbox"].filled-in + label:after, #requestSuccess.requestFormInline [type="checkbox"].filled-in + label:after {
  border-color: #888;
}

#requestForm.requestFormInline #requestError, #requestSuccess.requestFormInline #requestError {
  color: #707070 !important;
}

/* Kitchen */
.kitchen .panel-title a {
  color: #3D3D3D;
}

.kitchen .panel-title a:hover {
  color: #fc7174;
}

.kitchen .panel-title.collapsed i.fa-caret-down {
  display: none;
}

.kitchen .panel-title.collapsed i.fa-caret-right {
  display: inline;
}

.kitchen .panel-title i.fa-caret-down {
  display: inline;
}

.kitchen .panel-title i.fa-caret-right {
  display: none;
}

.kitchen .card {
  box-shadow: none;
  margin: 0px 0px 30px 0;
}

.kitchen .kitchen-item .panel {
  border: none;
  box-shadow: none;
}

.kitchen .kitchen-item .panel .panel-heading {
  background: none;
  padding: 15px 0 5px 0;
  margin: 0px;
  font-size: 18px;
  cursor: pointer;
  color: #a0a0a0;
}

.kitchen .kitchen-item .panel .panel-body {
  padding: 15px 0px;
}

.kitchen .kitchen-item.grid-item-xs .card {
  max-height: 345px;
}

.kitchen .kitchen-item.grid-item-xs .kitchen-cover {
  height: 345px;
}

.kitchen .kitchen-item .card {
  overflow: hidden;
  display: block;
}

.kitchen .kitchen-item .kitchen-cover {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.4);
}

.kitchen .kitchen-item .kitchen-cover-content {
  position: absolute;
  width: 100%;
  left: 0;
  bottom: 0;
  text-align: center;
  color: #fff;
}

.kitchen .kitchen-item .kitchen-title {
  color: #fff;
  font-size: 18px;
  padding: 0 15px;
}

.kitchen .kitchen-item .kitchen-badge {
  display: inline-block;
  background: #3FBAB9;
  padding: 10px 20px;
  margin-top: 10px;
  color: #fff;
  font-size: 14px;
  font-weight: 600;
}

.kitchen .kitchen-item .kitchen-price {
  display: block;
  padding: 18px 0 20px;
  font-size: 14px;
  font-weight: 600;
}

.kitchen .kitchen-item .kitchen-price-black {
  color: #000;
  display: inline-block;
  padding: 10px 20px;
  margin-top: 10px;
  font-size: 14px;
  font-weight: 600;
}

.kitchen .kitchen-item .kitchen-description {
  width: 100%;
  color: #fff;
  padding: 0 30px 30px 30px;
  font-size: 13px;
  line-height: 1.3;
}

.kitchen .kitchen-item .kitchen-description h3 {
  color: #fff;
  margin-bottom: 5px;
}

.kitchen .kitchen-item .kitchen-description .kitchen-description-title {
  display: none;
  margin-bottom: 25px;
}

.kitchen .kitchen-item .kitchen-description .kitchen-description-action {
  text-align: center;
  padding-bottom: 30px;
  bottom: 0px;
  position: absolute;
}

.kitchen .kitchen-item .kitchen-description .kitchen-description-subtitle {
  max-height: 98px;
  margin-bottom: 25px;
  overflow: hidden;
}

.kitchen .kitchen-item .kitchen-description .kitchen-description-commercial {
  max-height: 150px;
  overflow: hidden;
  margin-bottom: 25px;
  line-height: 16px;
}

.kitchen .kitchen-item .kitchen-description .kitchen-description-provider {
  height: 68px;
  overflow: hidden;
}

.kitchen .kitchen-item .kitchen-description .kitchen-description-action-viewlist,
.kitchen .kitchen-item .kitchen-description .kitchen-description-provider-viewlist {
  display: none;
}

.kitchen .kitchen-item.grid-item-sm .kitchen-description h3 {
  font-size: 16px;
}

.kitchen .kitchen-item.grid-item-sm .kitchen-description-commercial {
  display: none;
}

.kitchen .kitchen-item.grid-item-lg .kitchen-description-commercial {
  display: none;
}

.kitchen.view-list .card {
  width: 270px;
}

.kitchen.view-list .kitchen-cover .kitchen-title {
  display: none;
}

.kitchen.view-list .kitchen-description {
  position: absolute;
  left: 0;
  top: 0;
  padding-left: 300px;
  padding-top: 20px;
  display: inline-block;
  width: 100%;
  color: #333;
  background: #eaeaea;
  height: 240px;
}

.kitchen.view-list .kitchen-description h3 {
  color: #333;
}

.kitchen.view-list .kitchen-description .kitchen-description-title {
  display: block;
  margin-bottom: 15px;
}

.kitchen.view-list .kitchen-description .kitchen-description-title h3 {
  margin: 0;
  font-size: 25px;
  line-height: 30px;
}

.kitchen.view-list .kitchen-description .kitchen-description-subtitle {
  max-height: 34px;
  margin-bottom: 15px;
}

.kitchen.view-list .kitchen-description .kitchen-description-action-viewlist {
  display: block;
  position: absolute;
  bottom: 30px;
  left: 300px;
}

.kitchen.view-list .kitchen-description .kitchen-description-provider-viewlist {
  display: inline;
  font-weight: 700;
}

.kitchen.view-list .kitchen-description .kitchen-description-provider {
  margin-top: 10px;
}

.kitchen.view-list .kitchen-description .kitchen-description-action,
.kitchen.view-list .kitchen-description .kitchen-description-commercial,
.kitchen.view-list .kitchen-description .kitchen-description-subtitle h3,
.kitchen.view-list .kitchen-description .kitchen-description-provider h3 {
  display: none;
}

@media (max-width: 768px) {
  #searchResults .kitchen .kitchen-description .kitchen-description-commercial {
    display: none;
  }
  #searchResults .kitchen.view-list .kitchen-item {
    height: 570px;
  }
  #searchResults .kitchen.view-list .kitchen-item .card {
    margin-bottom: 0;
    width: 100%;
    height: 300px;
  }
  #searchResults .kitchen.view-list .kitchen-item .kitchen-cover .kitchen-cover-content {
    bottom: 160px;
  }
  #searchResults .kitchen.view-list .kitchen-item .kitchen-description {
    position: relative;
    padding-left: 30px;
  }
  #searchResults .kitchen.view-list .kitchen-item .kitchen-description .kitchen-description-action-viewlist {
    left: 30px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  #searchResults .kitchen .kitchen-description .kitchen-description-commercial {
    display: none;
  }
  #searchResults .kitchen.view-list .kitchen-description .kitchen-description-subtitle {
    display: none;
  }
}

.miscPage h2, .miscPage h3, .miscPage h4, .miscPage h5 {
  padding: 15px 0px 10px;
  margin: 0px;
  font-size: 18px;
  color: #888;
}
