footer.footer {
  font-size: 12px;
  color: #999;
  background-repeat: no-repeat;
  background-position: center; 
  background-size: cover;
  padding: 15px 0;

  h1, h2, h3, h4, h5 {
    margin-top: 0;
  }

  i.fa {
    font-size: 14px;
  }

  ul.list-pipe {
    list-style: none;
    padding: 0;
    margin: 0;

    li {
      float: left;
      padding: 0 10px;
      // line-height: 33px;

      &:after {
        border-left: 1px solid #ccc;
        content: '';
        display: block;
        height: 14px;
        left: 10px;
        margin-left: -11px;
        margin-top: -18px;
      }

      &:last-child {
        padding-right: 0;
      }

      &:first-child {
        padding-left: 0;

        &:after {
          border: none;
        }
      }

      a {
        padding: 5px 0px;
      }
    }
  }
}

footer.footer.footer-dark {
  background-color: $gray-1;
  color: $gray-3;
  font-weight: 600;

  a {
    color: $gray-3;
    font-weight: 600;
    text-transform: uppercase;

    &:hover, &:focus, &:active {
      color: $gray-5;
    }
  }

  hr {
    border-color: $gray-3;
  }

  ul.list-pipe {

    li:after {
      border-color: $gray-3;
    }
  }
}

@media (max-width: 767px) {

  footer.footer {
    text-align: center;

    ul.list-pipe {
      clear: both;
      display: table;
      margin: auto;
    }
  }
}