pre {
  margin-top: 20px !important;

  &.code-left:before,
  &.code-center:before,
  &.code-right:before,
  &.code-center-left:before,
  &.code-center-right:before,
  &.code-middle-left:before,
  &.code-middle-right:before {
    content:"";
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 14px 14px 14px;
    border-color: transparent transparent #bbb transparent;
    position: absolute;
    left: 40px;
    top: 7px;
  }

  &.code-center:before {
    left: 49%;
  }

  &.code-right:before {
    left: 54%;
  }

  &.code-center-left:before {
    left: 15%;
  }

  &.code-center-right:before {
    left: 82%;
  }

  &.code-middle-left:before {
    left: 24%;
  }

  &.code-middle-right:before {
    left: 74%;
  }

  code[class*="language-"]:before {
    color: #333;
    content: attr(class);
    font-size: 12px;
    right: 15px;
    padding: 6px 12px;
    position: absolute;
    top: 21px;
    background: #ccc;
    text-shadow: none;
  }
}