
* {
  padding: 0;
  margin: 0;
}

html, body {
  height: 100%;
  width: 100%;
}

body {
  font-size: 14px;
  line-height: 22px;
  color: $gray-1;
  font-family: Arial, Helvetica, sans-serif;
  -webkit-font-smoothing: antialiased; /* Fix for webkit rendering */
  -webkit-text-size-adjust: 100%;
  margin: 0;
  padding: 0;
  /*padding-top: 88px;*/
  font-weight: 400;
  font-family: 'Open Sans', sans-serif;
  overflow-x: hidden;
  -webkit-font-smoothing: antialiased;
  // .fa {
  //   line-height: 22px;
  // }
}

body.noscroll { 
  position: fixed; 
  overflow-y: scroll;
}

b, strong {
  font-weight: 600;
}

a {
  outline: none !important;
  color: $link-color;

  &:link, &:visited {
    text-decoration: none;
  }

  &:hover, &:focus, &:active {
    text-decoration: none;//underline;
    color: lighten($link-color, 15%);
  }

  i { 
    text-decoration: none;
    // margin-right: 6px;
    cursor: pointer;

    &.waves-effect {
      margin: 0;
    }
  } 
}

i {
  cursor: default;
}

hr {
  border-color: #ccc;

  &.hr-dashed {
    border-style: dashed;
  }

  &.hr-thick {
    border-width: 2px;
  }

  &.hr-primary {
    border-color: $primary-color;
  }

  &.hr-white {
    border-color: #fff;
  }
}

pre {
  margin: 0.5rem 0 1rem;
  border-radius: 2px;
}

h1, h2, h3, h4, h5, h6 {
  font-weight: 300;
  letter-spacing: 1px;
  line-height: 1.4;
  margin-top: 5px;
  margin-bottom: 5px;

  &.line-center {
    position: relative;
    margin-top: 20px;
    margin-bottom: 50px;
    text-align: center;

    &:after {
      position: absolute;
      display: block;
      height: 2px;
      width: 120px;
      // bottom: 50px;
      left: 50%;
      margin-top: 20px;
      margin-left: -60px;
      background: $primary-color-light;
      content: '';
    }
  }

  &.line-left {
    position: relative;
    padding-left: 15px;
    margin-top: 20px;
    margin-bottom: 60px;
    text-align: left;

    &:before {
      position: absolute;
      display: block;
      height: 33px;
      width: 4px;
      left: 0;
      margin-top: 0px;      
      background: $primary-color-light;
      content: '';
    }
  }

  &.line-bottom {
    position: relative;
    margin-top: 20px;
    margin-bottom: 60px;
    text-align: left;

    &:after {
      position: absolute;
      display: block;
      height: 2px;
      width: 100%;
      left: 0;
      margin-top: 20px;      
      background: $primary-color;
      content: '';
      /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/$primary-color+0,ffffff+100 */
      background: $primary-color; /* Old browsers */
      background: -moz-linear-gradient(left,  $primary-color 0%, #ffffff 100%); /* FF3.6-15 */
      background: -webkit-linear-gradient(left,  $primary-color 0%,#ffffff 100%); /* Chrome10-25,Safari5.1-6 */
      background: linear-gradient(to right,  $primary-color 0%,#ffffff 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$primary-color', endColorstr='#ffffff',GradientType=1 ); /* IE6-9 */
    }
  }
}

h1, h2, h3 {
  i.fa {
    margin-top: 5px;
  }
}

ul.pagination {
  margin: 0;
}

p, ul {
  margin-top: 0;
  margin-bottom: 10px;
}

ul li {
  line-height: inherit;
}

ul.bullet {
  list-style: none;
  padding: 0;
  margin-left: 15px;

  li { 
    padding-left: 5px; 
    text-indent: 0px;
    line-height: 24px;

    &:before {
      content: "●";
      padding-right: 2px;
      color: $primary-color;
      margin-left: -20px;
      width: 20px;
      display: inline-block;
    }
  }

  &.bullet-square li:before {
    content: "■";
  }

  &.bullet-star li:before {
    content: "★";
  }

  &.bullet-alt li:before {
    content: "๏";
  }

  
}

img.img-responsive {
  width: 100%;
}

blockquote {
  font-size: 16px;
  line-height: 22px;
  margin-bottom: 6px;
  padding: 15px 20px 0;
  margin: 10px;
  border-left: 2px solid #eee;
  font-family: 'Open Sans', sans-serif;
  text-align: left;
  /*color: #333;*/

  &.blockquote-primary {
    border-color: $primary-color-light;
  }

  &.double-quote {
    color: #fff;
    padding: 0;
    margin:0;
    border: none;
    display: table-cell;
    font-size: 30px;
    height: 100%;
    letter-spacing: 0.1em;
    line-height: 35px;
    text-align: center;
    text-shadow: 0 0 4px #030303;
    text-transform: uppercase;
    vertical-align: middle;

    &:before {
      content: '\f10d';
      font-family: 'FontAwesome';
      margin-right: 0.5em;
      color: #fff;
    }

    &:after {
      content: '\f10e';
      font-family: 'FontAwesome';
      margin-left: 0.5em;
      color: #fff;
    }
  }
}

article {

  &.slice-border {
    border-bottom: 3px solid #ccc;
  }

  &.slice {
    // background-color: #fff;

  }
}

// article, section {
//   h1 {
//     font-weight: 500;
//   }
// }

.well {
  border: 0;
  border-radius: 0;
  &.dark {
    background: $primary-color;
  }
}

.inline-block {
  display: inline-block;
}

.display-none {
  display: none;
}

.btn-link {
  color: $primary-color;
  text-transform: uppercase;

  > i {
    margin-right: 5px;
  }

  &:hover, &:focus, &:active {
    text-decoration: none;
    color: $primary-color;
    text-decoration: underline;
  }
}

.btn-white {
  color: #fff;
  text-transform: uppercase;

  > i {
    margin-right: 5px;
  }

  &:hover, &:focus, &:active {
    color: #fff;
    border: 1px solid #fff;
  }
}

.btn-default {
  background-color: #e5e5e5;

  &:hover, &:focus, &:active {
    background-color: darken(#e5e5e5, 8%);
  }
}

.btn-hero {
    padding: 20px 24px;
    font-size: 22px;
}

.btn-wizard {
  display: inline-block;
  position: relative;
  color: #fff;
  padding: 12px 24px;
  overflow: hidden;
  -webkit-transition: color 0.3s;
  transition: color 0.3s;
  -webkit-transition-timing-function: cubic-bezier(0.75, 0, 0.125, 1);
  transition-timing-function: cubic-bezier(0.75, 0, 0.125, 1);
  z-index: 2;
  text-transform: uppercase;
  font-size: 16px;
  font-weight: 600;

  &:before { // &:after 
    content: '';
    position: absolute;
    z-index: -1;
    border-radius: inherit;
  }
  // &:after {
    // top: 2px;
    // left: 2px;
    // right: 2px;
    // bottom: 2px;
    // background: transparent;
    // border: 1px solid #fff;
    // bottom: 0;
    // left: 50%;
    // width: 30px;
    // margin-left: -15px;
    // height: 0px;
  // }
  &:before {
    background: transparent;
    background: rgba($primary-color-light, .1);
    border: 1px solid #ddd;
    bottom: 0px;
    left: 50%;
    margin-left: -15px;
    width: 30px;
    height: 0px;
    // -webkit-transform: translate3d(0, 100%, 0);
    // transform: translate3d(0, 100%, 0);
    -webkit-transition: -webkit-height 300ms;
    transition: height 300ms, width 300ms, margin 300ms, left 300ms, border-width 300ms;
    -webkit-transition-timing-function: cubic-bezier(0.75, 0, 0.125, 1);
    transition-timing-function: cubic-bezier(0.75, 0, 0.125, 1);
  }
  // > span {
  //   padding-left: 0.35em;
  // }
  &:focus, &.focus {
    color: #f00;
  }

  &:not(.active):hover {
    color: #fff;
    &:before {
      width: 70px;
      // left: 10%;
      margin-left: -35px;
      // border-width: 2px;
      transition: height 300ms, width 300ms, margin 300ms, border-width 0s;
    }
  }

  &.active,
  &.active.focus, 
  &.active:focus, &.active:hover, &:active.focus, &:active:focus, &:active:hover,
  &:active, &.active {
    color: #fff;
    &:before {
      // -webkit-transform: translate3d(0, 0, 0);
      // transform: translate3d(0, 0, 0);
      height: 100%;
      width: 100%;
      // left: 0;
      margin-left: -50%;
      border-width: 2px;
      transition: height 300ms, width 300ms, margin 300ms, border-width 0s;
    }
  }
}

.btn-primary {
  background-color: $primary-color;

  &:hover,
  &.active.focus, 
  &.active:focus, &.active:hover, &:active.focus, &:active:focus, &:active:hover ,
  &:focus, &.focus,
  &:active, &.active {
    color: #fff;
    background-color: darken($primary-color, 8%);
  }
}

.btn.btn-secondary {
  background-color: transparent;
  border-color: $primary-color;
  color: $primary-color;

  &.active.focus, &.active:focus, &.focus, &.active, &:active.focus, &:active:focus, &:focus, &:hover {
    background-color: darken($primary-color, 8%);
    border-color: darken($primary-color, 8%);
    color: #fff;
    box-shadow: none;
  }
}

.btn {
  border-color: transparent;
  border-radius: 2px;
  line-height: 22px;
  font-size: 13px;

  &.btn-primary, .btn-secondary, .btn-default {
    &.active.focus, &.active:focus, &.focus, &.active, &:active.focus, &:active:focus, &:focus, &:hover {
      outline: none;
      border-color: transparent;
      box-shadow: none;
    }
  }
}

.btn-group.open .dropdown-toggle {
  box-shadow: none;
}

// .btn-default {
//   color: #333;
//   background-color: #f5f5f5;
//   // border-color: #ccc;
// }

// .btn-primary {
//   color: #fff;
//   background-color: $primary-color;
//   // border-color: $primary-color;
// }

// div[role='anchor'] {
//   height: 53px;
//   width: 100%;
// }

.breadcrumb {
  background-color: transparent;
  margin: 0;
  padding: 0;

  li+li:before {
    color: #666;
  }
}

.breadcrumb.breadcrumb-dark {
  background-color: transparent;

  li {
    color: #ccc;

    &:before {
      color: #ccc;
    }

    a {
      color: #fff;

      &:hover, &:active {
        color: #ccc;
      }
    }
  }
} 

// .hero, .hero #noice, .hero .hero-container {
//     height: 100%;
//     min-height: 400px;
// }

/* Flame special effect */

.flame-container{
  margin: 80px auto;
  width: 60px;
  height: 60px;
  position: absolute;
  top: -97px;
  left: -10px;
  transform-origin:center bottom;
  animation-name: flicker;
  animation-duration:30ms;
  animation-delay:600ms;
  animation-timing-function: ease-in;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  transform:rotate(-90deg);
  z-index: -1;

  .flame{
    bottom:0;
    position:absolute;
    border-bottom-right-radius: 50%;
    border-bottom-left-radius: 50%;
    border-top-left-radius: 50%;
    transform:rotate(-45deg) scale(0.5,0.5);
  }

  .yellow{
    left:15px; 
    width: 30px;
    height: 30px;
    background:gold;
    box-shadow: 0px 0px 9px 4px gold;
  }

  .orange{
    left:10px; 
    width: 40px;
    height: 40px;
    background:orange;
    box-shadow: 0px 0px 9px 4px orange;
  }

  .red{
    left:5px;
    width: 50px;
    height: 50px;
    background:OrangeRed;
    box-shadow: 0px 0px 5px 4px OrangeRed;
  }

  .white{
    left:15px; 
    bottom:-4px;
    width: 30px;
    height: 30px;
    background:white;
    box-shadow: 0px 0px 9px 4px white;
  }

  .circle{
    border-radius: 50%;
    position:absolute;  
  }
}


@keyframes flicker {
  0%   {transform: rotate(-91deg);}
  20%  {transform: rotate(-89deg);}
  40%  {transform: rotate(-91deg);}
  60%  {transform: rotate(-89deg) scaleY(1.04);}
  80%  {transform: rotate(-91deg) scaleY(0.98);}
  100% {transform: rotate(-89deg);}
}