.carousel#resultDetailsCarousel {
  h1, h2, h3, h4, p {
    color: #fff;
    text-shadow: 0px 0px 2px rgba(0, 0, 0, 1);
    text-align: center;
  }

  .carousel-indicators {

    position: absolute;
    left: 50%;
    bottom: 30px;
    // z-index: 15;
    width: 90%;
    height: 24px;
    padding-left: 0;
    margin-left: -45%;
    text-align: center;
    list-style: none;
    opacity: 0.7;

    .active {
      width: 24px;
      height: 24px;
      background-color: $secondary-color;
      border-color: #fff;
    }

    li {
      display: inline-block;
      width: 24px;
      height: 24px;
      margin: 0 10px;
      text-indent: -999px;
      cursor: pointer;
      background-color: $gray-6;
      border: 1px solid $gray-6;
      border-radius: 12px;
      position: relative;
    }
  }

  .carousel-caption {
    padding: 0;
    margin: 0;
  }

  .carousel-control {
    width: 100px;
    opacity: 1;

    &.left, &.right {
      background-image: none;
      background: none;

      &:hover {
        .fa-angle-left, .fa-angle-right {
          background: rgba(20,20,20, .8);
        }
      }
    }

    .fa-angle-left, .fa-angle-right {
      position: absolute;
      top: 50%;
      z-index: 5;
      display: inline-block;
      font-size: 93px;
      height: 100px;
      margin-left: -50%;
      margin-top: -50px;
      padding-right: 7px;
      width: 100px;
      background: rgba(50,50,50, .6)

    }

    .fa-angle-right {
      padding-right: 0px;
      padding-left: 7px;
      right: 0;
    }
  }
}

.carousel#homeKitchenCarousel {
  h1, h2, h3, h4, p {
    color: #fff;
    text-shadow: 0px 0px 2px rgba(0, 0, 0, 1);
    text-align: center;
  }

  .carousel-indicators {

    position: absolute;
    left: 50%;
    bottom: -30px;
    // z-index: 15;
    width: 90%;
    height: 24px;
    padding-left: 0;
    margin-left: -45%;
    text-align: center;
    list-style: none;

    .active {
      width: 14px;
      height: 14px;
      background-color: $primary-color;
      border-color: $primary-color;
    }

    li {
      display: inline-block;
      width: 14px;
      height: 14px;
      margin: 0 4px;
      text-indent: -999px;
      cursor: pointer;
      background-color: $gray-5;
      border: 2px solid $gray-5;
      border-radius: 7px;
      position: relative;
    }
  }

  .carousel-caption {
    padding: 0;
    margin: 0;
  }

  .carousel-control {
    width: 50px;
    opacity: 1;

    &.left {
      left: -50px;
    } 

    &.right {
      right: -50px;
    }

    &.left, &.right {
      background-image: none;
      background: none;

      &:hover {
        .fa-angle-left, .fa-angle-right {
          //background: rgba(20,20,20, .8);
          color: $primary-color-dark;
        }
      }
    }

    .fa-angle-left, .fa-angle-right {
      position: absolute;
      top: 46%;
      z-index: 5;
      display: inline-block;
      font-size: 93px;
      height: 100px;
      margin-left: -50%;
      margin-top: -50px;
      padding-right: 7px;
      width: 50px;
      color: $primary-color;
      text-shadow: none;
      background: none;//rgba(50,50,50, .6)

    }

    .fa-angle-right {
      padding-right: 0px;
      padding-left: 7px;
      right: 0;
    }
  }
}