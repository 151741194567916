/* Home */

#resultDetailsCarousel {
  background: #eee;

  * {
    text-shadow: none !important;
  }

  .item {
    width: 100%;
    height: auto;
  }

  .carousel-inner > .item > img {
    min-width: 100%;
    height: auto;
  }
}

.nav.nav-tabs {
  li {
    &.active a {
      color: $primary-color;
    }

    &:not(.active) a {
      color: #666;
    }

    a {
      font-weight: 600;
      font-size: 14px;
      text-transform: uppercase;
      border-radius: 2px;
    }
  }
}