/* Kitchen */

.kitchen {

  .panel-title {
    a {
      color: $gray-2;  

      &:hover {
        color: $primary-color;
      }
    }
    &.collapsed {
      i.fa-caret-down {
        display: none;
      }
      i.fa-caret-right {
        display: inline;
      }
    }
    i.fa-caret-down {
      display: inline;
    }
    i.fa-caret-right {
      display: none;
    }
  }

  .card {
    box-shadow: none;
    margin: 0px 0px 30px 0;
  }

  .kitchen-item {
    // background: $primary-color-dark;

    .panel {
      border: none;
      box-shadow: none;

      .panel-heading {
        background: none;
        padding: 15px 0 5px 0;
        margin: 0px;
        font-size: 18px;
        cursor: pointer;
        color: $gray-4;
      }

      .panel-body {
        padding: 15px 0px;
      }

    }

    &.grid-item-xs {

      .card {
        max-height: 345px;
      }

      .kitchen-cover {
       height: 345px;
      }
    }

    .card {
      overflow: hidden; 
      display: block;
    }

    .kitchen-cover {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background: rgba(0,0,0, .4);
    }

    .kitchen-cover-content {
      position: absolute;
      width: 100%;
      left: 0;
      bottom: 0;
      text-align: center;
      color: #fff;
    }
 
    .kitchen-title {
      color: #fff;
      font-size: 18px;
      padding: 0 15px;
    }

    .kitchen-badge {
      display: inline-block;
      background: $secondary-color;
      padding: 10px 20px;
      margin-top: 10px;
      color: #fff;
      font-size: 14px;
      font-weight: 600;
    }

    .kitchen-price {
      display: block;
      padding: 18px 0 20px;
      font-size: 14px;
      font-weight: 600;
    }

    .kitchen-price-black {
      color: #000;
      display: inline-block;
      padding: 10px 20px;
      margin-top: 10px;
      font-size: 14px;
      font-weight: 600;
    }


    .kitchen-description {
      width: 100%;
      color: #fff;
      padding: 0 30px 30px 30px;
      font-size: 13px;
      line-height: 1.3;
      
      h3 {
        color: #fff;
        margin-bottom: 5px;
      }

      .kitchen-description-title {
        display: none;
        margin-bottom: 25px;
      }

      .kitchen-description-action {
        text-align: center;
        padding-bottom: 30px;
        // border-bottom: 1px solid $primary-color-light;
        bottom: 0px;
        position: absolute;
      }

      .kitchen-description-subtitle {
        max-height: 98px; 
        margin-bottom: 25px;
        overflow: hidden;
       }

      .kitchen-description-commercial {
        max-height: 150px; 
        overflow: hidden;
        margin-bottom: 25px;
        line-height: 16px;
      }

      .kitchen-description-provider {
        height: 68px; 
        overflow: hidden;
      }

      .kitchen-description-action-viewlist,
      .kitchen-description-provider-viewlist {
        display: none;
      }
    }

    &.grid-item-sm {

      .kitchen-description {
        h3 {
          font-size: 16px;
        }
      } 

      .kitchen-description-commercial {
        display: none;
      }
    }

    &.grid-item-lg {

      .kitchen-description-commercial {
        display: none;
      }
    }    
  }
}

.kitchen.view-list {

  .card {
    width: 270px;
  }
  
  .kitchen-cover {
    .kitchen-title {
      display: none;
    }
  }

  .kitchen-description  {
    position: absolute;
    left: 0;
    top: 0;
    padding-left: 300px;
    padding-top: 20px;
    display: inline-block;
    width: 100%;
    color: #333;
    background: $gray-7;
    height: 240px;
    
    h3 {
      color: #333;
    }

    .kitchen-description-title {
      display: block;
      margin-bottom: 15px;
      h3 {
        margin: 0;
        font-size: 25px;
        line-height: 30px;
      }
    }

    .kitchen-description-subtitle {
      max-height: 34px;
      margin-bottom: 15px;
    }

    .kitchen-description-action-viewlist {
      display: block;
      position: absolute;
      bottom: 30px;
      left: 300px;
    }

    .kitchen-description-provider-viewlist {
      display: inline;
      font-weight: 700;
    }

    .kitchen-description-provider {
      margin-top: 10px;
    }

    .kitchen-description-action,
    .kitchen-description-commercial,
    .kitchen-description-subtitle h3,
    .kitchen-description-provider h3 {
      display: none;
    }
  }
}

@media(max-width:768px) {

  #searchResults .kitchen {
    .kitchen-description {
      .kitchen-description-commercial {
        display: none;
      }
    }
  }

  #searchResults .kitchen.view-list {

    .kitchen-item {
      height: 570px;

      .card {
        margin-bottom: 0;
        width: 100%;
        height: 300px;
      }

      .kitchen-cover {
        .kitchen-cover-content {
          bottom: 160px;
        }
      }

      .kitchen-description  {
        position: relative;
        padding-left: 30px;

        .kitchen-description-action-viewlist {
          left: 30px;
        }
      }
    }
  }
}

@media(min-width:768px) and (max-width:991px){
  #searchResults .kitchen {
    .kitchen-description {
      .kitchen-description-commercial {
        display: none;
      }
    }
  }
  #searchResults .kitchen.view-list {
    .kitchen-description {
      .kitchen-description-subtitle {
        display: none;
      }
    }
  }
}