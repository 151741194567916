@media (min-width: 768px) {
  .row-sm-flex, 
  .row-sm-flex [class*='col-sm-'] {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    flex: 1 0 auto;
  }

  .row-margin {
    margin-bottom: 30px;
  }

}

@media (min-width: 992px) {
  .row-md-flex, 
  .row-md-flex [class*='col-md-'] {  
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    flex: 1 0 auto;
  }
}

@media (min-width: 1200px) {
  .row-lg-flex, 
  .row-lg-flex [class*='col-lg-'] {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    flex: 1 0 auto;
  }
}