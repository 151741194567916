.modal {

  .modal-content {
    border-radius: 0;

    h1 {
      margin-top: 15px;
      .close {
        font-size: 50px;
        color: #999;
      }
    }

    .modal-header {
      padding: 12px 40px;
      font-size: 1em;
      font-weight: 300;
    }

    .modal-body {
      padding: 12px 40px;
    }

    .modal-footer {
      padding: 12px 40px; 
    }
  }

  .modal-primary {

    .modal-header {
      border-bottom: 1px solid $primary-color;
    }

    .modal-footer {
      border-top: 1px solid $primary-color;
    }
  }

  .close {
    color: #333;
    font-size: 27px;
    font-weight: 300;
    opacity: 1;
  }
}

@media(min-width:768px) {
  .modal .modal-dialog {
    width: 750px;
  }
}