input:not([type]), 
input[type="text"], 
input[type="password"], 
input[type="email"], 
input[type="url"], 
input[type="time"], 
input[type="date"], 
input[type="datetime-local"], 
input[type="tel"], input[type="number"], input[type="search"], 
textarea.materialize-textarea {
  font-size: 14px;
}

.input-field label {
  font-weight: 400;
  font-size: 14px;
  left: 0px;
}
.input-field label.active {
  font-size: 12px;
}


label {
  font-size: 13px;
  font-weight: 500;
  color: #333;
}

[type="checkbox"] + label {
  padding: 7px 15px 7px 43px !important;
  margin: 0;
  font-size: 13px;
  display: inline-block;
  width: 100%;
  height: auto;
  line-height: 15px;
}

[type="checkbox"].filled-in {

  cursor: pointer;

  &:not(:checked) + label:after {
    border: 1px solid #5a5a5a;
    left: 15px;
    top: 4px;
  }

  &:checked + label:before {
    top: 4px;
    left: 16px;
  }

  &:checked + label:after {
    top: 4px;
    left: 15px;
    border: 1px solid $primary-color;
    background-color: $primary-color;
  }
}