.fa-rounded,
.fa-rounded-primary {
  border-radius: 50%;
  width: 41px;
  height: 41px;
  display: inline-block;
  text-align: center;
  padding-top: 11px; 
  background-color: #fff;
  border: 2px solid #333;
  margin: 2px 0;
}

a {
  .fa-rounded {
    border-color: $link-color;
    color: $link-color;
    text-decoration: none;
  }

  &:hover, &:active {
    .fa-rounded {
      border-color: lighten($link-color, 15%);
      color: lighten($link-color, 15%);
    }
  }
}

.fa-rounded-primary {
  background-color: $link-color;
  border: 2px solid $link-color;
  color: #fff;
}