/* Home */

#searchWizard.carousel {
  background-repeat: no-repeat;
  background-size: cover;


  * {
    text-shadow: none !important;
  }

  .carousel-indicators {

    display: none;
    position: absolute;
    left: 50%;
    top: 60px;
    // z-index: 15;
    width: 507px;
    height: 24px;
    padding-left: 0;
    margin-left: -253px;
    text-align: center;
    list-style: none;

    .active {
      width: 24px;
      height: 24px;
      background-color: $primary-color;
    }

    li {
      display: inline-block;
      width: 24px;
      height: 24px;
      margin: 0 25px;
      text-indent: -999px;
      cursor: pointer;
      background-color: transparent;
      border: 2px solid $primary-color;
      border-radius: 12px;
      position: relative;

      // &:first-child {
      //   margin-left: 0;
      // }
      // &:last-child {
      //   margin-right: 0;
      // }

      &:not(:last-child):before {
        content: "";
        border-bottom: 2px solid $primary-color;
        // margin: 0 24px;
        position: absolute;
        top: 10px;
        width: 27px;
        left: 22px;
        z-index: 16;
      }
      &:not(:first-child):after {
        content: "";
        border-bottom: 2px solid $primary-color;
        // margin: 0 24px;
        position: absolute;
        top: 10px;
        width: 27px;
        right: 22px;
        z-index: 16;
      }
    }
  }

  .carousel-caption {
    top: 120px;
    left: 0;
    right: 0;
  }

  .item {
    min-height: 430px;
    // position: relative;

    >img {
      height: 430px;
      width: 2200px;
    }
  }

  .widget-x-image {
    border: 1px solid #fff;
    border-radius: 3px;
    margin: 5px auto;
  }

  .widget-0 {

    .carousel-caption {
      top: 80px;
      min-height: 350px;

      .widget-0-top {        
        min-height: 350px;

        .widget-0-top-layer {
          background: rgba(0,0,0, .8);
          padding: 30px;
          min-height: 260px;
        }
      }
    }
  }
}

@media(max-width:768px){

  #searchWizard.carousel {
    .item > img {
      height: 530px;
    }
    .widget-0 {
      .carousel-caption {
        top: 110px; 
        .widget-0-top {
          min-height: auto;
        }

        .widget-0-bottom {
          padding-bottom: 100px;
        }
      }
    }

    .size-xxl {
      font-size: 18px;
    }
  }
}

@media(min-width:768px) and (max-width:991px){
  .home-blogentry-latest {
    height: 74px;
    overflow: hidden;
    margin-bottom: 10px;
  }
}