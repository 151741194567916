/* Blog */

.blog {

  .blog-item {
    background: $gray-7;
    margin-bottom: 30px;

    h3, h4 {
      padding: 20px 25px 0px;
      margin-bottom: 5px;
      font-weight: 800;
      height: 70px;
      line-height: 26px;
      display: block;
      overflow: hidden;
      text-overflow: ellipsis;

      &:hover {
        color: $primary-color;
      }
    }

    p {
      font-family: 'Montserrat', sans-serif;
      font-weight: 700;
      font-size: 15px;
      padding: 5px 25px 10px;
      color: $gray-2;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      // text-transform: uppercase;
    }

    a.blog-link {
      display: block;
      font-weight: 600;
      width: 100%;
      padding: 20px 25px;
      text-transform: uppercase;
      color: darken($primary-color, 10%);

      &:hover {
        text-decoration: underline;
      }
    }

    .blog-no-image {
      width: 100%;
      padding: 25%;
      background: $gray-4;
      position: relative;
      display: inline-table;

      > span {
        color: $gray-7;
        font-size: 17px;
        font-weight: 600;
        position: absolute;
        top: 30px;
        left: 25px;
      }
    }

    &.blog-item-small {    
      h3, h4 {
        // height: 57px;
        padding: 15px 20px 0px;
        margin: 0px; 
      } 

      p {
        padding: 0px 20px 5px;
        font-size: 13px;
      } 

      a.blog-link-small {
        padding: 15px 20px;
        font-size: 14px;
      }
    }
  }
}

.blog-content {
  margin-top: 20px;

  h1, h2 {
    color: #f00;
  }

  a {
    font-weight: 600;
    &:hover {
      color: $primary-color;
      text-decoration: underline;
    }
  }

  h3, h4, h5 {
    padding: 15px 0px 10px;
    margin: 0px; 
    font-size: 18px; 
    color: $gray-3;
    font-weight: 600;
  }

  ul {
    list-style: none;
    padding: 0;
    margin-left: 15px;

    li { 
      padding-left: 5px; 
      text-indent: 0px;
      line-height: 24px;

      &:before {
        content: "●";
        padding-right: 2px;
        color: $primary-color;
        margin-left: -20px;
        font-size: 18px;
        width: 20px;
        display: inline-block;
      }
    }
  }
}

@media (min-width: 992px) {
  .blog {
    .blog-item {
      h3, h4 {
        height: auto;
      }
    }
  }
}

@media(max-width:991px) {

  .blog {
    .blog-item {
      h3, h4 {
        height: 95px;
      }
    }
  }
}