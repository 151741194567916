.jQuerySlider {
  margin: 5px 10px 0px;

  &.ui-widget.ui-widget-content {
    border: none;
    height: 1px;
  }

  &.ui-slider-horizontal {
    height: 4px;
  }

  &.ui-corner-all {
    border-radius: 2px !important;
    border-color: #555;
  }

  &.ui-slider .ui-slider-handle {
    background: #fff;
    border-radius: 5px;
    border: none;
    width: 11px;
    height: 11px;
    top: -5px;
    outline: none;
    cursor: pointer;
  }

  .ui-slider-range {
    background: #65b32e;/*#1d6599;*/
  }
}