// Variables
@import "variables";

.dev {
  background: purple;
  color: white;
  padding: 5px 10px;
  font-weight: 700;
  font-size: 12px;
  display: inline-block;
}

body {
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  // text-transform: uppercase;
  color: $gray-2;
}

h3, h4, h5, h6 {
  font-weight: 800;
}

.is-title {
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  line-height: 1.4;
}

.size-xxl { font-size: 33px;}
.size-xl { font-size: 24px; }
.size-l { font-size: 22px; }
.size-m { font-size: 18px; }
.size-s { font-size: 15px; font-weight: 700; }
.size-xs { font-size: 13px; font-weight: 700; }

h1.hero {
  font-size: 45px;
  font-weight: 700;    
  line-height: 1.1;
  margin-bottom: 20px;
}

h2.hero {
  font-size: 26px;
  font-weight: 700;    
  line-height: 1.1;
  text-transform: none;
}

.btn {
  text-transform: uppercase;
  font-weight: 600;
}

.lead {
  font-size: 17px;
  font-weight: 400;
}

.breadcrumb li {
  text-transform: uppercase;
  font-weight: 600;
  color: $gray-4;
  a {
    padding: 20px 0;
    display: inline-block;
    color: $gray-2;
    &:hover, &:focus {
      color: $primary-color;
    }
  }
}

.stroke {
  text-decoration: line-through;
}


.checkmark{
  display:inline-block;
  padding: 10px;
  
  &:after{
    content: '';
    display: block;
    width: 12px;
    height: 27px;
    border: solid $primary-color;
    border-width: 0 3px 3px 0;
    transform: rotate(45deg);
  }
}

#return-to-top {
  position: fixed;
  z-index: 5;
  bottom: 120px;
  right: 20px;
  background: $primary-color-dark;
  background: rgba($primary-color-dark, .85);
  width: 50px;
  height: 50px;
  display: block;
  text-decoration: none;
  border-radius: 35px;
  display: none;
  text-align: center;
  transition: background, padding 0.3s ease;
  i {
    color: #fff;
    transition: background, padding 0.3s ease;
    font-size: 35px;
    padding-top: 9px;
  }
  &:hover {
    background: $primary-color-dark;
    i {
      color: #fff;
      padding-top: 5px;
    }
  }
}

@media(max-width:767px) {
  #return-to-top {
    display: none !important;
  }
}

// Imports
@import "app/_october";
// @import "app/_jquery-ui"; Slide has been replaced by a drop doen menu - 2017-05-08
@import "app/_home";
@import "app/_blog";
@import "app/_search";
@import "app/_search_result";
@import "app/_request_form";
@import "app/_kitchen";
@import "app/_misc";