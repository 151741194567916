.plan {  
  .card {
    border: 0;
    border-radius: 0;
    transform: scale(0.85);
    transition: all 0.2s ease-in-out;

    &.plan-primary {
      transform: scale(0.91);
      transition: all 0.2s ease-in-out;
    }

    &:hover {
      transform: scale(0.94);
      transition: all 0.2s ease-in-out;

      &.plan-primary {
        transform: scale(1);
        transition: all 0.2s ease-in-out;
      }
    }

    .list-group-item {
      border-radius: 0;
    }
  }
}