/* Search Musterküchen */

#searchNavi {
  box-shadow: inset 0 -1px 0px #fff; /* fix background image retina half line */
  // margin-bottom: 20px;
  // border-bottom: 1px solid red;

  div.searchHint {
    position: absolute;
    left: 40px;
    top: 50px;
    display: none;
    z-index: 1;
  }

  ul.searchNaviOptions{
    list-style: none;
    padding: 0;
    margin: 0;

    > li {
      float: left;
      padding: 0;
      margin: 0;
      position: relative;
      background: $primary-color;
      margin-bottom: 72px;
      width: 150px;

      &.searchDistance {
        float: right;
        background: $primary-color-dark;
        margin-bottom: 0px;
        z-index: 2;

        > div.btn-group {

          > a, > span {
            padding: 12px 10px;
            text-align: center;

            &:before, &:after {
              border: none;
            }

            a.btn-link {
              padding: 4px 0 4px 4px;
              line-height: 1;
            }
          }
        }

        .distanceOption {
          display: block !important;

          #option-zip {
            display: block !important;
            background: $primary-color-dark;
            border-color: $primary-color-dark;
            z-index: 1;

            > span {
              padding: 12px 10px;
            }

            table {
              margin-left: 10px;
              width: 140px;
              margin-top: 4px;
              margin-bottom: 5px;
            }
            table td {
              padding: 0;
              width: 32px;
            }
          }
        }
      }

      > div.btn-group {

        > a, > span {
          display: inline-block;
          padding: 12px 15px;
          color: #fff;
          font-weight: 600;
          width: 150px;
          position: relative;
          text-transform: uppercase;
          box-shadow: none;

          &:before {
            border-left: 1px solid lighten($primary-color, 16%);
            content: '';
            position: absolute;
            height: 22px;
            left: 0px;
          }

          &:after {
            border-left: 1px solid lighten($primary-color, 16%);
            content: '';
            position: absolute;
            height: 22px;
            right: -1px;
          }

          &:hover, &:focus, &.active {
            // color: #fff;

            .caret {
              color: #fff;
            }
          }

          .caret {
            float: right;
            display: inline-block;
            margin-top: 8px;
            color: lighten($primary-color, 16%);
          }
        }

        .dropdown-menu {
          border-radius: 0;
          min-width: 151px;
          margin-top: -1px;
          border-color: $primary-color;

          li {
            a, span {
              text-transform: uppercase;
              padding: 0;
            }

            span {
              padding: 5px 15px;
            }
          }
        }
      }

      #deleteFilter {
        display: none;
        color: #333;
        position: absolute;
        font-size: 11px;
        text-transform: uppercase;
        font-weight: 600;
        right: 0;
        padding: 1px;
        text-align: center;
        width: 150px;
        top: 46px;

        &:hover, &:focus, &.active {
          background: #ddd;
        }

      }
    }
  }

  .force-zip-style {

    background: #fff;
    padding: 12px 15px !important;
    display: block;

    &:hover, &:focus, &.active {
      background: #fff;
    }

    input[type="text"] {
      border: 1px solid #ccc;
      padding: 4px 0px 4px 6px;
      margin-bottom: 5px;
      width: 54px;
      height: 20px;
      float: left;

      &:focus {
        border: 1px solid $primary-color !important;
        box-shadow: none !important;
      }
    }

    a {
      display: block;
      float: right;
      width: 50px;
      padding: 3px !important;
    }
  }

  .distanceOption {

    > li {
      > div {
        padding: 0px 0 4px 10px;

        .input-field {

          margin-top: 0;

          label {
            color: #fff;
            font-size: 11px;
            font-weight: 600;
            top: -1px;

            &.active {
              font-size: 9px;
              color: #ddd;
              transform: translateY(-15px);
            }
          }

          .caret {
            color: #fff;
            margin-left: -12px;
            margin-top: 0px;
          }

          .form-control {
            margin-bottom: 8px;
          }

          input[type="text"] {
            color: #fff;
            width: 47px;
            float: left;
            font-size: 11px;
            font-weight: 600;
            height: 18px;
            border-bottom: 1px solid $primary-color;

            &:focus {
              border-color: #fff;
              // outline: 0;
              // -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba($primary-color, 0.6);
              box-shadow: 0 1px 0 0 #fff;
            }
          }
        }

        div.btn-group {
          margin-left: 10px;
          

          &.open .dropdown-toggle {
            box-shadow: none;
          }

          label {
            cursor: pointer;
          }

          input[type="text"] {
            width: 72px;
            cursor: pointer;
            background-color: transparent !important;

            &:focus {
              // color: $primary-color-dark;
            }
          }

          .dropdown-menu {
            border-radius: 0;
            min-width: 100%;
            margin-top: -5px;
            border-color: $gray-5;

            li {
              a, span {
                text-transform: none;
                padding: 3px 6px;
                color: #666;
                font-size: 11px;
                font-weight: 600;

                &:hover {
                  background: $primary-color;
                  color: #fff;
                }
              }

              span {
                padding: 5px 15px;
              }
            }
          }
        }

        .error-message {
          background: darken($primary-color, 8);
          color: #fff;
          font-size: 11px;
          font-weight: 600;
          padding: 2px 10px;
          margin-left: -10px;
          display: none;
        }

        a.btn {
          padding: 0px 0px 0px 0px;
          display: inline-block;
          float: left;
          font-size: 10px;
          line-height: 18px;
          text-transform: none;

          &.btn-link {
            width: 48px;
            margin-right: 10px;
          }
          &.btn-primary {
            width: 72px;
          }
        }
      }
    }
  }

  .searchOption, ._distanceOption {
    list-style: none;
    padding: 0;
    margin: 0;
    position: absolute;

    > li {
      float: left;
      padding: 0;
      margin: 0;

      > div {
        background: #484848;
        border-bottom: 1px solid #ccc;
        border-left: 1px solid #ccc;
        position: relative;
        width: 150px;
        display: none;

        &.active {
          display: block;
        }

        > span {
          padding: 4px 0 4px 15px;
        }

        span, table, label, input {
          color: #fff;
          text-transform: uppercase;
          font-size: 11px;
          font-weight: 600;
          margin-bottom: 0;
        }

        input[type="text"] {
          border: 1px solid #ccc;
          padding: 0px 5px;
          background: lighten($primary-color-dark, 5);
          height: auto;
          margin-bottom: 5px;
          margin-left: 10px;
          width: 70px;
          float: left;

          &:focus {
            border: 1px solid #fff !important;
            box-shadow: none !important;
            background: lighten($primary-color-dark, 10);
          }

        }

        a.btn {
          padding: 0px 10px 0px 10px;
          display: inline-block;
          position: absolute;
          right: 10px;
          font-size: 14px;
        }

        a:not(.btn) {
          color: #ccc;
          padding: 0px 15px 0px 15px;
          display: inline-block;
          position: absolute;
          right: 0px;
          font-size: 14px;

          &:hover, &:focus, &.active {
            color: #fff;
            background: #666;
          }
        }
      }
    }
  }
}

@media(max-width: 480px){
  #searchNavi .container {
    width: 330px;
  }
}

@media (min-width: 481px) and (max-width: 991px){
  #searchNavi .container {
    width: 481px;
  }
}

#settingsNavi {

  .settingsNaviView {
    position: relative;
    > span {
      font-size: 13px;
      font-weight: 700;
      color: #999;
      text-transform: uppercase;
      display: inline-block;
      margin-right: 7px;
    }
  }

  .settingsNaviResults h1 {
    font-size: 27px;
    font-weight: 700;
    color: #333;
    line-height: 1.2;
    margin: 0;
    // text-transform: uppercase;
    text-align: center;
    b {
      font-weight: 700;
      color: $primary-color;
    }
  }

  .settingsNaviSort {
    text-align: right;
    > span {
      display: inline-block;
      text-transform: uppercase;
      color: #aaa;
      padding: 2px;
      font-size: 13px;
      line-height: 14px;
      font-weight: 700;
    }

    .dropdown-menu .fa-spin {
      display: none;
    }
    .selection .settingsNaviSortNew {
      display: none;
    }

    .btn-group {
      margin-left: 10px;
      
      button {
        text-align: left;
        width: 200px;

        .caret {
          float: right;
          display: inline-block;
          margin-top: 9px;
        }
      }
    }
  }

  .dropdown-menu {
    border-radius: 0;
    min-width: 100%;
    margin-top: -1px;
    border-color: $primary-color;

    li {
      a, span {
        text-transform: uppercase;
        padding: 5px 10px;
        color: #333;
      }

      span {
        padding: 5px 15px;
      }
    }
  }
}

@media(max-width:991px){
  #settingsNavi {
    .settingsNaviView, .settingsNaviResults, .settingsNaviPaginierung, .settingsNaviSort {
      text-align: center;
      padding: 10px 0;
    }
  }
}

#searchResults {
  // * { box-sizing: border-box; }

  // body {
  //   font-family: sans-serif;
  // }

  .col-xs-12 {
    padding-left: 0;
    padding-right: 0;
  }
 // view-list

  .grid.view-list {
    background: #fff;
    max-width: 1170px;

    .grid-item {
      float: left;
      margin-left: 15px;
      overflow: hidden;
      width: 100%;
      height: 270px;
      border-right: 30px solid #fff;

      .card {
        height: 240px;
        float: left;
      }
    }
  }
  // Grid
  .grid.view-grid {
    background: #fff;
    max-width: 1170px;

    &:after {
      content: '';
      display: block;
      clear: both;
    }

    // Grid Item
    .grid-item {
      float: left;
      // background: $primary-color-dark;
      // border-bottom: 30px solid #fff;
      margin-left: 15px;
      overflow: hidden;
      
      &.gigante {
        background: $primary-color-dark;
        border-bottom: 30px solid #fff;
      }

      &.grid-item-xs {
        width: 100%;
        height: 345px;
        margin-left: 0px;

        &.gigante {
          height: 690px;
        }

        .card {
          height: 315px;
        }
      }

      &.grid-item-sm {
        width: 345px;
        height: 345px;

        &.gigante {
          height: 690px;
        }

        .card {
          height: 315px;
        }
      }

      &.grid-item-md {
        width: 454px;
        height: 454px;

        &.gigante {
          height: 908px;
        }

        .card {
          height: 424px;
        }
      }

      &.grid-item-lg {
        width: 360px;
        height: 360px;

        &.gigante {
          height: 720px;
        }

        .card {
          height: 330px;
        }
      }
    }
  }
}
